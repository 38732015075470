<template>
    <LoadingModal v-if="loading" header="Add or update insured" open @close="close" />
    <ConfirmUpdateInsuredModal v-else-if="insuredExists"
        :insured="existedInsured"
        :saving="saving"
        :external-error="updateError"
        @close="close"
        @updateInsured="updateInsured"
    />
    <AddIndividualInsuredModal
        v-else-if="opened"
        :insured-fields="insuredFieldsStatus.list"
        :coverage-types="coverageTypesStatus.list"
        :saving="saving"
        :alert-config="alertConfig"
        @close="close"
        @submit="addInsured"
    />
</template>

<script lang="ts">
    import { Component, Prop, Vue, Watch } from '@evidentid/vue-property-decorator';
    import { InsuranceInsuredField, InsuranceInsuredInput, OperationError } from '@evidentid/rpweb-api-client/types';
    import { OperationStatus } from '@evidentid/vue-commons/store/OperationStatus';
    import { AlertType } from '@evidentid/dashboard-commons/components/Alert/types';
    import { BatchAddInsuredsStatus, BatchUpdateInsuredsStatus } from '@/modules/insured-management/vuex';
    import AddIndividualInsuredModal from '../components/AddIndividualInsuredModal/AddIndividualInsuredModal.vue';
    import LoadingModal from '@/components/LoadingModal.vue';
    import ConfirmUpdateInsuredModal
        from '@/modules/insured-management/components/ConfirmUpdateInsuredModal/ConfirmUpdateInsuredModal.vue';
    import { convertInsuredInputDataToView } from '@/modules/insured-management/utils/insuredProcessing';

    @Component({
        components: { AddIndividualInsuredModal, LoadingModal, ConfirmUpdateInsuredModal },
    })

    export default class AddIndividualInsuredProcedure extends Vue {
        @Prop({ type: String, default: '' })
        private rpName!: string;
        private opened: boolean = true;
        private saving: boolean = false;
        private alertConfig: { type: AlertType, title: string } | null = null;
        private updateError: string = '';
        private needCollectEvidence: boolean = false;

        @Watch('addInsuredsStatus')
        private async onAddInsuredsStatusChange(addInsuredsStatus: BatchAddInsuredsStatus) {
            this.saving = addInsuredsStatus.status === OperationStatus.loading;
            if (addInsuredsStatus.status === OperationStatus.success) {
                if (addInsuredsStatus.failures.length === 0) {
                    this.$emit('finish', this.needCollectEvidence);
                } else if (addInsuredsStatus.failures[0].error.error === OperationError.unknown) {
                    this.alertConfig = {
                        type: 'danger',
                        title: 'Cannot connect the insured, an unknown error occurred.',
                    };
                }
            } else if (addInsuredsStatus.status === OperationStatus.error) {
                this.alertConfig = {
                    type: 'danger',
                    title: 'Sorry, something went wrong during add insureds. Please try again later.',
                };
            }
        }

        @Watch('updateInsuredsStatus')
        private async onUpdateInsuredsStatusChange(updateInsuredsStatus: BatchAddInsuredsStatus) {
            this.saving = updateInsuredsStatus.status === OperationStatus.loading;
            if (updateInsuredsStatus.status === OperationStatus.success) {
                if (updateInsuredsStatus.failures.length > 0 &&
                    updateInsuredsStatus.failures.every((x) => x.error.error === OperationError.unknown)) {
                    this.updateError = 'Cannot update the insureds, unknown errors occurred.';
                } else {
                    this.$emit('finish', this.needCollectEvidence);
                }
            } else if (updateInsuredsStatus.status === OperationStatus.error) {
                this.updateError = 'Sorry, something went wrong during update insureds. Please try again later.';
                this.alertConfig = {
                    type: 'danger',
                    title: this.updateError,
                };
            }
        }

        private get addInsuredsStatus(): BatchAddInsuredsStatus {
            return this.$store.state.insuredManagement.addInsuredsStatus[this.rpName] || {
                status: OperationStatus.uninitialized,
                count: 0,
                successCount: 0,
                successes: [],
                failures: [],
            };
        }

        private get updateInsuredsStatus(): BatchUpdateInsuredsStatus {
            return this.$store.state.insuredManagement.updateInsuredsStatus[this.rpName] || {
                status: OperationStatus.uninitialized,
                successCount: 0,
                failureCount: 0,
                totalCount: 0,
                successes: [],
                failures: [],
            };
        }

        private get insuredFieldsStatus(): { status: OperationStatus, list: InsuranceInsuredField[] } {
            return this.$store.state.dashboard.insuredFields[this.rpName] || {
                status: OperationStatus.uninitialized,
                list: [],
            };
        }

        private get coverageTypesStatus(): { status: OperationStatus, list: string[] } {
            return this.$store.state.dashboard.coverageTypes[this.rpName] || {
                status: OperationStatus.uninitialized,
                list: [],
            };
        }

        private get loading(): boolean {
            return this.insuredFieldsStatus.status === OperationStatus.loading
                || this.coverageTypesStatus.status === OperationStatus.loading;
        }

        private get insuredExists(): boolean {
            return this.addInsuredsStatus.failures.length > 0 &&
                this.addInsuredsStatus.failures[0].error.error === OperationError.keyConflict;
        }

        private get existedInsured(): InsuranceInsuredInput | null {
            return this.insuredExists
                ? convertInsuredInputDataToView(this.addInsuredsStatus.failures[0].element)
                : null;
        }

        private mounted() {
            if (this.insuredFieldsStatus.status !== OperationStatus.loading) {
                this.$store.actions.dashboard.loadInsuredFields({ rpName: this.rpName });
            }
            if (this.coverageTypesStatus.status !== OperationStatus.loading) {
                this.$store.actions.dashboard.loadCoverageTypes({ rpName: this.rpName });
            }
        }

        private close(): void {
            this.opened = false;
            this.$store.actions.insuredManagement.clearAddInsuredsStatus({ rpName: this.rpName });
            this.$emit('abort');
        }

        private updateInsured(insured: InsuranceInsuredInput) {
            this.$store.actions.insuredManagement.updateInsureds({
                rpName: this.rpName,
                insureds: [ insured ],
            });
        }

        private addInsured(insured: InsuranceInsuredInput, hasPastExpiration: boolean) {
            this.alertConfig = null;
            this.needCollectEvidence = hasPastExpiration;
            this.$store.actions.insuredManagement.addInsureds({ rpName: this.rpName, insureds: [ insured ] });
        }
    }
</script>

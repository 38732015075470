<template>
    <div class="PopoverStandardFilters">
        <div class="PopoverStandardFilters__checkboxBlock">
            <div class="PopoverStandardFilters__paused">
                <Checkbox :value="paused" @input="onPausedChange">
                    Paused
                </Checkbox>
            </div>
            <div class="PopoverStandardFilters__showDeactivated">
                <Checkbox :value="showDeactivated" @input="onShowDeactivatedChange">
                    Show deactivated
                </Checkbox>
            </div>
        </div>
        <FormElement class="PopoverStandardFilters__containWords" label="Contains">
            <FormInput>
                <input v-model="localFilters.search" type="text" placeholder="Type">
            </FormInput>
        </FormElement>

        <FormElement class="PopoverStandardFilters__insuredName" label="Insured Name">
            <FormInput>
                <input v-model="localFilters.displayName" type="text" placeholder="Type Insured Name">
            </FormInput>
        </FormElement>

        <FormElement class="PopoverStandardFilters__contactName" label="Primary Contact Name">
            <FormInput>
                <input v-model="localFilters.contactName" type="text" placeholder="Type Primary Contact Name">
            </FormInput>
        </FormElement>

        <FormElement class="PopoverStandardFilters__contactEmail" label="Primary Contact Email">
            <FormInput>
                <input v-model="localFilters.contactEmail" type="text" placeholder="Type Primary Contact Email">
            </FormInput>
        </FormElement>

        <FormElement class="PopoverStandardFilters__contactPhoneNumber" label="Contact Phone Number">
            <FormInput>
                <input
                    v-model="localFilters.contactPhoneNumber"
                    type="text"
                    placeholder="Type Contact Number"
                    @paste="onPastePhoneNumber"
                    @keydown="numberKeysOnly"
                >
            </FormInput>
        </FormElement>

        <FormElement
            ref="complianceStatusFormElement"
            class="PopoverStandardFilters__complianceStatus"
            label="Compliance Status"
        >
            <Dropdown
                multi-select
                :options="complianceStatusOptions"
                :selected="selectedComplianceStatusOptions"
                @input="onComplianceStatusChanged"
                @open="scrollToElement('complianceStatusFormElement')"
            />
        </FormElement>

        <FormElement
            ref="verificationStatusFormElement"
            class="PopoverStandardFilters__verificationStatus"
            label="Verification Status"
        >
            <Dropdown
                multi-select
                :options="verificationStatusOptions"
                :selected="selectedVerificationStatusOptions"
                @input="onVerificationStatusChanged"
                @open="scrollToElement('verificationStatusFormElement')"
            />
        </FormElement>

        <FormElement class="PopoverStandardFilters__expiration" label="Expiration Date">
            <FormInput class="PopoverStandardFilters__expiresAfter">
                <DatePicker
                    :value="localFilters.expiresAfterOrOn"
                    placeholder="From"
                    enable-clear
                    @input="onAfterDateChange"
                />
            </FormInput>
            <div class="PopoverStandardFilters__expirationSeparator" />
            <FormInput class="PopoverStandardFilters__expiresBefore">
                <DatePicker
                    :value="localFilters.expiresBeforeOrOn"
                    :min-date="localFilters.expiresAfterOrOn"
                    placeholder="To"
                    enable-clear
                    @input="onBeforeDateChange"
                />
            </FormInput>
        </FormElement>

        <FormElement
            ref="effectiveGroupFormElement"
            class="PopoverStandardFilters__effectiveGroup"
            label="Effective Group"
        >
            <Dropdown
                multi-select
                :options="effectiveGroupOptions"
                :selected="selectedEffectiveGroups"
                @input="onEffectiveGroupsChanged"
                @open="scrollToElement('effectiveGroupFormElement')"
            />
        </FormElement>

        <FormElement
            ref="coverageCriteriaGroupFormElement"
            class="PopoverStandardFilters__coverageCriteriaGroup"
            label="Coverage Criteria Group"
        >
            <CoverageCriteriaGroupFilter
                class="PopoverStandardFilters__coverageCriteriaGroup"
                :options="coverageCriteriaGroupOptions"
                :selected="selectedCoverageCriteriaGroups"
                :filter-type="coverageCriteriaGroupFilterType"
                @clear="onCoverageCriteriaGroupFilterClear"
                @input="clearCoverageCriteriaFilterTypeIfNeeded"
                @select="onCoverageCriteriaGroupsChange"
                @changeFilterType="onCoverageCriteriaGroupFilterTypeChange"
                @open="scrollToElement('coverageCriteriaGroupFormElement')"
            />
        </FormElement>
    </div>
</template>

<script lang="ts">
    import moment from 'moment';
    import pickBy from 'lodash/pickBy';
    import isEqual from 'lodash/isEqual';
    import omit from 'lodash/omit';
    import intersection from 'lodash/intersection';
    import sortBy from 'lodash/sortBy';
    import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
    import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
    import { Button } from '@evidentid/dashboard-commons/components/Button';
    import Dropdown from '@evidentid/dashboard-commons/components/Dropdown/Dropdown.vue';
    import { FormElement, FormInput } from '@evidentid/dashboard-commons/components/Form';
    import { FilterType, InsuredFilters } from '@/modules/insured-filtering/types';
    import { DropdownOption } from '@evidentid/dashboard-commons/components/Dropdown/types';
    import {
        InsuranceComplianceStatusLabel,
        InsuranceCoverageCriteriaGroup,
        InsuranceEffectiveGroup,
        InsuranceVerificationStatus,
        InsuranceVerificationStatusLabel,
    } from '@evidentid/rpweb-api-client/types';
    import { InsuranceComplianceStatus } from '@evidentid/insurance-facing-lib/models/insured-details';
    import { DatePicker } from '@evidentid/dashboard-commons/components/DatePicker';
    import { Checkbox } from '@evidentid/dashboard-commons/components/Checkbox';
    import CoverageCriteriaGroupFilter
        from '@/modules/insured-filtering/components/CoverageCriteriaGroupFilter/CoverageCriteriaGroupFilter.vue';

    @Component({
        components: {
            FontAwesomeIcon, Button, DatePicker, Dropdown, FormElement, FormInput, Checkbox,
            CoverageCriteriaGroupFilter,
        },
    })
    export default class PopoverStandardFilters extends Vue {
        @Prop({ type: Object, default: () => ({}) })
        private filters!: InsuredFilters;

        @Prop({ type: Array, default: () => [] })
        private coverageCriteriaGroups!: InsuranceCoverageCriteriaGroup[];

        @Prop({ type: Array, default: () => [] })
        private effectiveGroups!: InsuranceEffectiveGroup[];

        private coverageCriteriaGroupsMap: Record<string, InsuranceCoverageCriteriaGroup> = {};
        private localFilters: InsuredFilters = { ...this.filters };
        private complianceStatusLabelMap: Record<InsuranceComplianceStatus, InsuranceComplianceStatusLabel> =
            Object.entries(InsuranceComplianceStatus).reduce((acc, [ key, value ]) => ({
                ...acc,
                [value]: (InsuranceComplianceStatusLabel as Record<string, InsuranceComplianceStatusLabel>)[key],
            }), {} as Record<InsuranceComplianceStatus, InsuranceComplianceStatusLabel>);
        private verificationStatusLabelMap: Record<InsuranceVerificationStatus, InsuranceVerificationStatusLabel> =
            Object.entries(InsuranceVerificationStatus).reduce((acc, [ key, value ]) => ({
                ...acc,
                [value]: (InsuranceVerificationStatusLabel as Record<string, InsuranceVerificationStatusLabel>)[key],
            }), {} as Record<InsuranceVerificationStatus, InsuranceVerificationStatusLabel>);

        private complianceStatusOptions: DropdownOption[] = [];
        private verificationStatusOptions: DropdownOption[] = [];
        private readonly effectiveGroupsLabelDelimiter = ' + ';
        private readonly effectiveGroupsQueryDelimiter = '+';

        private get selectedComplianceStatusOptions(): DropdownOption[] {
            const statusStrings = this.localFilters.complianceStatus?.split(',') || [];
            const filteredStatus =
                intersection(statusStrings, Object.values(InsuranceComplianceStatus)) as InsuranceComplianceStatus[];
            return this.localFilters.complianceStatus
                ? this.getComplianceStatusDropdownOptions(filteredStatus)
                : [];
        }

        private get selectedVerificationStatusOptions(): DropdownOption[] {
            const statusStrings = this.localFilters.verificationStatus?.split(',') || [];
            const filteredStatus = intersection(statusStrings, Object.values(InsuranceVerificationStatus)) as
                InsuranceVerificationStatus[];
            return this.localFilters.verificationStatus
                ? this.getVerificationStatusDropdownOptions(filteredStatus)
                : [];
        }

        private get showDeactivated() {
            return this.filters.showDeactivated === 'true';
        }

        private get paused() {
            return this.filters.paused === 'true';
        }

        private get coverageCriteriaGroupOptions(): DropdownOption[] {
            return this.coverageCriteriaGroups
                .map((ccg) => ({ label: ccg.displayName, value: ccg.id }))
                .sort((a, b) => a.label.localeCompare(b.label));
        }

        private get selectedCoverageCriteriaGroups(): DropdownOption[] {
            const coverageCriteriaGroupIds = this.localFilters.coverageCriteriaGroupId?.split(',') || [];
            return coverageCriteriaGroupIds.reduce((options, id) => {
                const coverageCriteriaGroup = this.coverageCriteriaGroupsMap[id];
                if (coverageCriteriaGroup) {
                    return [
                        ...options,
                        { label: coverageCriteriaGroup.displayName, value: coverageCriteriaGroup.id },
                    ];
                }
                return options;
            }, [] as DropdownOption[]);
        }

        private get effectiveGroupOptions(): DropdownOption[] {
            const options = this.effectiveGroups.map((groups) => {
                const groupNames = groups.map((groupId) => this.coverageCriteriaGroupsMap[groupId].displayName || '');
                const sortedGroupNames =
                    sortBy(groupNames, (name) => name.toLowerCase()).join(this.effectiveGroupsLabelDelimiter);
                return { label: sortedGroupNames, value: groups };
            });
            return sortBy(options, (option) => option.label.toLowerCase());
        }

        private get selectedEffectiveGroups(): DropdownOption[] {
            const effectiveGroups = this.localFilters.effectiveGroup?.split(',') || [];
            return effectiveGroups.map((ids) => {
                const effectiveGroup = ids.split(this.effectiveGroupsQueryDelimiter);
                return {
                    label: this.buildEffectiveGroupLabel(effectiveGroup),
                    value: effectiveGroup,
                };
            });
        }

        private get coverageCriteriaGroupFilterType(): FilterType {
            return this.filters.coverageCriteriaGroupFilterType || 'OR';
        }

        @Watch('filters', { immediate: true })
        private onFiltersChanged() {
            if (!isEqual(this.filters, this.localFilters)) {
                this.localFilters = { ...this.filters };
            }
        }

        @Watch('localFilters', { deep: true })
        private onLocalFiltersChanged() {
            if (!isEqual(this.filters, this.localFilters)) {
                this.$emit('input', { ...this.localFilters });
            }
        }

        @Watch('coverageCriteriaGroups', { immediate: true })
        private onCoverageCriteriaGroupsChanged() {
            this.coverageCriteriaGroupsMap = this.coverageCriteriaGroups.reduce((map, ccg) => ({
                ...map,
                [ccg.id]: ccg,
            }), {} as Record<string, InsuranceCoverageCriteriaGroup>);
        }

        private mounted() {
            this.complianceStatusOptions =
                this.getComplianceStatusDropdownOptions(Object.values(InsuranceComplianceStatus))
                    .sort((a, b) => a.label.localeCompare(b.label));
            this.verificationStatusOptions =
                this.getVerificationStatusDropdownOptions(Object.values(InsuranceVerificationStatus)
                    .filter((status: string) => status !== InsuranceVerificationStatus.complete))
                    .sort((a, b) => a.label.localeCompare(b.label));
        }

        private getComplianceStatusDropdownOptions(statusList: InsuranceComplianceStatus[]): DropdownOption[] {
            return statusList.map((status) => ({ label: this.complianceStatusLabelMap[status], value: status }));
        }

        private getVerificationStatusDropdownOptions(statusList: InsuranceVerificationStatus[]): DropdownOption[] {
            return statusList.map((status) => ({ label: this.verificationStatusLabelMap[status], value: status }));
        }

        private onComplianceStatusChanged(options: DropdownOption[]): void {
            const complianceStatus = options.map((option) => option.value).join(',');
            this.localFilters = pickBy({ ...this.localFilters, complianceStatus }, Boolean);
        }

        private onVerificationStatusChanged(options: DropdownOption[]): void {
            const verificationStatus = options.map((option) => option.value).join(',');
            this.localFilters = pickBy({ ...this.localFilters, verificationStatus }, Boolean);
        }

        private onBeforeDateChange(date: string): void {
            this.localFilters = { ...this.localFilters, ...(date && { expiresBeforeOrOn: date }) };
        }

        private onAfterDateChange(date: string): void {
            if (moment(date).isAfter(moment(this.localFilters.expiresBeforeOrOn))) {
                this.localFilters = omit({ ...this.localFilters, ...(date && { expiresAfterOrOn: date }) }, 'expiresBeforeOrOn');
            } else {
                this.localFilters = { ...this.localFilters, ...(date && { expiresAfterOrOn: date }) };
            }
        }

        private numberKeysOnly(inputEvent: KeyboardEvent): void {
            const comboKey = inputEvent.metaKey || inputEvent.ctrlKey || inputEvent.altKey;
            if (!comboKey && inputEvent.key?.length === 1 && !/^[\d]$/.test(inputEvent.key)) {
                inputEvent.preventDefault();
            }
        }

        private onPastePhoneNumber(inputEvent: ClipboardEvent): void {
            inputEvent.preventDefault();
            if (inputEvent.clipboardData) {
                const filtered = inputEvent.clipboardData.getData('text/plain').replace(/[^\d]/g, '');
                (inputEvent.target as HTMLInputElement).value = filtered;
                this.localFilters.contactPhoneNumber = filtered;
            }
        }

        private onShowDeactivatedChange(): void {
            this.localFilters =
                { ...this.localFilters, showDeactivated: this.localFilters.showDeactivated ? undefined : 'true' };
        }

        private onPausedChange(): void {
            this.localFilters =
                { ...this.localFilters, paused: this.localFilters.paused ? undefined : 'true' };
        }

        private onCoverageCriteriaGroupsChange(options: DropdownOption[]): void {
            const coverageCriteriaGroupId = options.map((option) => option.value).join(',');
            this.localFilters = pickBy({ ...this.localFilters, coverageCriteriaGroupId }, Boolean);
            if (!this.localFilters.coverageCriteriaGroupFilterType) {
                this.localFilters = pickBy({ ...this.localFilters, coverageCriteriaGroupFilterType: 'OR' }, Boolean);
            }
        }

        private onEffectiveGroupsChanged(options: DropdownOption[]): void {
            const effectiveGroup = options.map((option) => option.value.join('+')).join(',');
            this.localFilters = pickBy({ ...this.localFilters, effectiveGroup }, Boolean);
        }

        private onCoverageCriteriaGroupFilterTypeChange(coverageCriteriaGroupFilterType: FilterType): void {
            this.localFilters = pickBy({ ...this.localFilters, coverageCriteriaGroupFilterType }, Boolean);
        }

        private onCoverageCriteriaGroupFilterClear(): void {
            this.localFilters = omit(this.localFilters, [ 'coverageCriteriaGroupId', 'coverageCriteriaGroupFilterType' ]);
        }

        private clearCoverageCriteriaFilterTypeIfNeeded(): void {
            if (!this.localFilters.coverageCriteriaGroupId) {
                this.localFilters = omit(this.localFilters, [ 'coverageCriteriaGroupFilterType' ]);
            }
        }

        private scrollToElement(refName: string): void {
            this.$nextTick(() => {
                const ref = (this.$refs[refName] as FormElement);
                if (ref) {
                    this.$emit('scrollTo', (ref.$el as HTMLElement).offsetTop);
                }
            });
        }

        private buildEffectiveGroupLabel(effectiveGroup: InsuranceEffectiveGroup): string {
            return effectiveGroup.map((id) => this.coverageCriteriaGroupsMap[id]?.displayName || '')
                .sort((a, b) => a.localeCompare(b)).join(this.effectiveGroupsLabelDelimiter);
        }
    }
</script>

<template>
    <div class="InsuredDetails">
        <!-- this may eventually be removed and implemented as tab level loader instead -->
        <PendingScreen v-show="loading" />
        <JsonSchemaCustomForm
            v-show="!loading"
            v-slot="{ fields }"
            v-model="value"
            class="InsuredDetails__content"
            :controller="controller"
        >
            <ActionableAlert
                v-if="alertConfig"
                class="InsuredDetails__alert"
                :title="alertConfig.title"
                :name="alertConfig.name"
                :type="alertConfig.type"
                :icon="alertConfig.icon"
                :action-title="alertConfig.actionTitle"
                @action="onAlertAction"
            />
            <InvalidChangesModal
                v-if="showConfirmationModal"
                @input="onModalAction"
            />
            <div class="InsuredDetails__header">
                <div class="InsuredDetails__headerDisplayNameBlock">
                    <div class="InsuredDetails__backButton" @click="onCloseButtonClick">
                        <FontAwesomeIcon
                            class="InsuredDetails__headerBackIcon"
                            :icon="faChevronLeft"
                        />
                    </div>
                    <JsonSchemaCustomFormElement
                        class="InsuredDetailsDisplayNameForm"
                        :field="fields.displayName"
                        :disabled="!insured.active"
                        @change="onDisplayNameChange"
                    />
                </div>
                <InsuredDetailsStatusBar
                    :updating-insured="updatingInsured"
                    :insured="insured"
                    :is-loading-submission-link="isLoadingSubmissionLink"
                    :is-collateral-enabled="isCollateralsFeaturesEnabled"
                    :submission-link-per-insured-id="submissionLinkPerInsuredId"
                    :insured-exception="insuredLevelException"
                    @grantExceptions="grantExceptions($event)"
                    @removeExceptions="removeExceptions"
                    @open-collateral-mapping-modal="openCollateralMappingModal"
                    @open-historic-coi-modal="openHistoricCoiModal"
                    v-on="statusBarUnhandledEvents"
                />
            </div>
            <Tabs initial-tab="insuredDetailsCoverageTypes">
                <Tab id="insuredDetailsOverview" name="Overview">
                    <JsonSchemaCustomFormElement
                        class="InsuredDetails__form"
                        touched
                        :field="fields.body"
                        :depth="0"
                        :custom-component-input="getCustomComponent"
                        :disabled="!insured.active"
                        :strings="jsonSchemaElementStrings"
                        @change="onBodyChange"
                    />
                    <InsuredDetailsCollateralSection
                        v-if="insuredFieldCollaterals !== null"
                        :insured-field-collaterals="insuredFieldCollaterals"
                        :collaterals="collaterals"
                        :insured-display-name="insured.displayName"
                        :updating-insured="updatingInsured"
                        @input="onCollateralsChange"
                        @on-collaterals-change="onCollateralsChange"
                    />
                </Tab>
                <Tab
                    id="insuredDetailsCoverageTypes"
                    class="InsuredDetails__coverageTypesTab"
                    name="Coverage Types"
                >
                    <EmptyCoverageTypes v-if="sortedDetailsList.length === 0" />
                    <div v-else>
                        <Policy
                            v-for="coverageDetails in sortedDetailsList"
                            :key="coverageDetails.coverageType"
                            :coverage-details="coverageDetails"
                            :all-coverages="sortedDetailsList"
                            :evaluation-errors="allCoverageTypeEvaluationErrors[coverageDetails.coverageType]"
                            :broker-info="brokerInfo"
                            :insured="insured"
                            :insured-coverage-criteria-groups="insuredCoverageCriteriaGroups"
                            :coverage-models="coverageModels"
                            :collateral-entities="collateralEntities"
                            @goToCriterion="goToCriterion"
                            @grantExceptions="grantExceptions($event, coverageDetails.coverage)"
                            @removeExceptions="removeExceptions"
                        />
                    </div>
                </Tab>
                <Tab
                    id="insuredDetailsCoverageCriteriaGroups"
                    class="InsuredDetails__coverageGroupsTab"
                    name="Coverage Criteria Groups"
                >
                    <InsuredCoverageCriteriaGroups
                        :insured-coverage-criteria-groups="sortedInsuredCriteriaGroups"
                        :insured="insured"
                        @goToCriteriaGroupList="navigateToCriteriaGroupList"
                        @goToCriteriaGroup="navigateToCriteriaGroup"
                    />
                </Tab>
                <Tab
                    v-if="isCollateralsFeaturesEnabled"
                    id="insuredDetailsCollaterals"
                    class="InsuredDetails__collateralsTab"
                    name="Collaterals"
                >
                    <CollateralTab
                        :collaterals="collateralEntities"
                        :sorted-details-list="sortedDetailsList"
                        :insured="insured"
                        @grant-exceptions="grantExceptions($event)"
                        @remove-exceptions="removeExceptions"
                    />
                </Tab>
                <template #headerEnd>
                    <EffectiveGroupsTooltipLabel :group-names="effectiveGroupNames" />
                </template>
            </Tabs>
        </JsonSchemaCustomForm>
    </div>
</template>

<script lang="ts">
    import { PropType } from 'vue';
    import { Component, Prop, Vue, Watch } from '@evidentid/vue-property-decorator';
    import { isEmpty, isEqual, mapValues, noop, omit, omitBy, sortBy, startCase } from 'lodash';
    import { OperationStatus } from '@evidentid/vue-commons/store/OperationStatus';
    import { faCheckCircle, faChevronLeft, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
    import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
    import { PatchOperationType } from '@evidentid/rpweb-api-client';
    import {
        InsuranceCoverageModel,
        InsuranceInsured, InsuranceInsuredCoverage,
        InsuranceInsuredCoverageCriteriaGroup,
        InsuranceInsuredCoverageDetails,
        InsuranceInsuredField,
        InsuranceInsuredFieldValue,
        InsuranceSchemaDisplayFormat,
    } from '@evidentid/rpweb-api-client/types';
    import {
        InsuranceCoverageType,
        InsuranceStatusOverride,
        InsuranceExceptionInput,
        InsuranceExceptionLevel,
    } from '@evidentid/insurance-facing-lib/models/insured-details';

    import createForm from '@evidentid/json-schema/createForm';
    import { JsonFormType } from '@evidentid/json-schema/interfaces/JsonForm';
    import { Alert } from '@evidentid/dashboard-commons/components/Alert';
    import { Badge } from '@evidentid/dashboard-commons/components/Badge';
    import { Button } from '@evidentid/dashboard-commons/components/Button';
    import {
        JsonSchemaCustomForm,
        JsonSchemaCustomFormElement,
        JsonSchemaFormStrings,
    } from '@evidentid/dashboard-commons/components/JsonSchemaForm';
    import { Tab, Tabs } from '@evidentid/dashboard-commons/components/Tabs';
    import ActionsButton from '@/modules/dashboard/components/ActionsButton/ActionsButton.vue';
    import { BrokerInfo } from '@/modules/dashboard/types';
    import ComplianceStatusBadge from '@/modules/dashboard/components/ComplianceStatusBadge/ComplianceStatusBadge.vue';
    import { buildInsuredDetailsJsonSchema } from '@/modules/insured-management/utils/buildInsuredDetailsJsonSchema';
    import { BatchUpdateInsuredsStatus, PatchInsuredsStatus } from '@/modules/insured-management/vuex';
    import ActionableAlert from '@/modules/insured-details/components/ActionableAlert/ActionableAlert.vue';
    import InsuredDetailsBooleanForm
        from '@/modules/insured-details/components/CustomJsonForms/InsuredDetailsBooleanForm.vue';
    import InsuredDetailsStringForm
        from '@/modules/insured-details/components/CustomJsonForms/InsuredDetailsStringForm.vue';
    import InvalidChangesModal from '@/modules/insured-details/components/InvalidChangesModal/InvalidChangesModal.vue';
    import InsuredDetailsStatusBar
        from '@/modules/insured-details/components/InsuredDetailsStatusBar/InsuredDetailsStatusBar.vue';
    import Policy from '@/modules/insured-details/components/Policy/Policy.vue';
    import { ActionableAlertConfig } from '@/modules/insured-details/components/ActionableAlert/types';
    import {
        editingRequiresPutOperation,
        getInsuredFieldsDiff,
        getStandardFieldsDiff,
        isDeletingInsureFieldItem,
        isNullishOrEmptyValue,
    } from '@/modules/insured-details/utils/insuredEditing';
    import { FormController, Pointer } from '@evidentid/json-schema/FormController';
    import { PendingScreen } from '@evidentid/dashboard-commons/screens/PendingScreen';
    import InsuredCoverageCriteriaGroups
        from '@/modules/insured-details/components/InsuredCoverageCriteriaGroups/InsuredCoverageCriteriaGroups.vue';
    import EffectiveGroupsTooltipLabel
        from '@/modules/insured-details/components/EffectiveGroupsTooltipLabel/EffectiveGroupsTooltipLabel.vue';
    import EmptyCoverageTypes from '@/modules/insured-details/components/EmptyCoverageTypes/EmptyCoverageTypes.vue';
    import InsuredDetailsCollateralSection
        from './components/InsuredDetailsCollateralSection/InsuredDetailsCollateralSection.vue';
    import { FieldEvaluationResultError } from '@/modules/decisioning-criteria/types';
    import { mapEvaluationErrors } from '@/modules/insured-details/utils/mapEvaluationErrors';
    import { CollateralEntity } from '@evidentid/rpweb-api-client/models/CollateralEntity.model';
    import CollateralTab from './components/CollateralTab/CollateralTab.vue';
    import { isCollateralInsuredField } from '@/utils/isCollateralInsuredField';
    import { InsuredFieldCollateral } from '@evidentid/rpweb-api-client/models/InsuredFieldCollateral.model';
    import InsuredDetailsAddressForm
        from '@/modules/insured-details/components/CustomJsonForms/InsuredDetailsAddressForm/InsuredDetailsAddressForm.vue';
    import JsonSchemaCountryEnumForm
        from '@evidentid/dashboard-commons/components/JsonSchemaForm/customElements/JsonSchemaCountryEnumForm.vue';
    import { getJsonSchemaCompFunction } from '@/utils/getJsonSchemaCustomCompFuntion';
    import { standardizeCriterionSchema } from '@/modules/decisioning-criteria/utils/standardizeCriterionSchema';
    import { CategorizedEnumLabels } from '@/modules/dashboard/models/CategorizedEnumLabels.model';
    import cloneDeep from 'lodash/cloneDeep';
    import {
        filterOutIsPresentUsedFields,
    } from '@/modules/insured-details/utils/filterOutIsPresentUsedFields/filterOutIsPresentUsedFields';

    interface InsuredDetailsValue {
        displayName: string;
        body: {
            profile: {
                contactEmail: string;
                contactName: string;
                contactPhoneNumber: string | null;
            };
            insuredFields?: InsuranceInsuredFieldValue;
        };
    }

    function buildInsuredDetailsController(insuredFields: InsuranceInsuredField[]): FormController {
        const schema = standardizeCriterionSchema(buildInsuredDetailsJsonSchema(
            insuredFields,
            [ 'required', (x) => x.name ],
            [ 'desc', 'asc' ],
        ));
        return new FormController(createForm(schema));
    }

    function concatCollateralsInsuredFields(
        insuredFieldValues?: InsuranceInsuredFieldValue,
        collaterals?: InsuredFieldCollateral[],
        collateralKey?: string,
    ): InsuranceInsuredFieldValue | null {
        if (!insuredFieldValues && !collaterals) {
            return null;
        }
        return {
            ...insuredFieldValues,
            ...(collaterals && collateralKey && { [collateralKey]: collaterals }),
        };
    }

    function getCollateralsOmittingNullProps(
        collaterals?: InsuredFieldCollateral[],
    ): InsuredFieldCollateral[] | undefined {
        if (!collaterals) {
            return collaterals;
        }

        return collaterals
            .map((collateral) => omitBy(collateral, isNullishOrEmptyValue) as InsuredFieldCollateral);
    }

    function getInsuredFieldsOmittingCollateralsNullProps(
        insuredFields: InsuranceInsuredFieldValue | null,
    ): InsuranceInsuredFieldValue | null {
        if (!insuredFields) {
            return insuredFields;
        }

        if (insuredFields.collaterals) {
            return {
                ...insuredFields,
                collaterals: getCollateralsOmittingNullProps(insuredFields.collaterals as InsuredFieldCollateral[]),
            };
        }

        return insuredFields;
    }

    @Component({
        components: {
            ActionableAlert, ActionsButton, Alert, Badge, Button, ComplianceStatusBadge, EffectiveGroupsTooltipLabel,
            EmptyCoverageTypes, FontAwesomeIcon, InsuredCoverageCriteriaGroups, InsuredDetailsStatusBar,
            InvalidChangesModal, JsonSchemaCustomForm, JsonSchemaCustomFormElement, PendingScreen, Policy, Tab, Tabs,
            InsuredDetailsCollateralSection, CollateralTab,
        },
    })
    export default class InsuredDetails extends Vue {
        @Prop({ type: Object, required: true })
        private insured!: InsuranceInsured;

        @Prop({ type: Array, default: () => [] })
        private insuredFields!: InsuranceInsuredField[];

        @Prop({ type: Array, default: () => [] })
        private coverageDetailsList!: InsuranceInsuredCoverageDetails[];

        @Prop({ type: Array, default: () => [] })
        private collateralEntities!: CollateralEntity[];

        @Prop({ type: Array, default: () => [] })
        private insuredCoverageCriteriaGroups!: InsuranceInsuredCoverageCriteriaGroup[];

        @Prop({ type: Array, default: () => [] })
        private currentlyUpdatedInsuredsIds!: string[];

        @Prop({ type: Boolean, default: false })
        private isLoadingSubmissionLink!: boolean;

        @Prop({ type: Boolean, default: false })
        private loading!: boolean;

        @Prop({ type: Object, default: {} })
        private submissionLinkPerInsuredId!: Record<string, string>;

        @Prop({ type: Boolean, default: false })
        private isCollateralEnabled!: boolean;

        @Prop({ type: Array, default: () => [] })
        private coverageModels!: InsuranceCoverageModel[];

        @Prop({ type: Object, default: () => ({}) })
        private externalAlertConfig!: ActionableAlertConfig;

        @Prop({ type: String as PropType<string>, default: '' })
        private collateralInsuredFieldKey!: string;

        @Prop({ type: Object as PropType<Record<string, any>>, default: '' })
        private enumLabels!: CategorizedEnumLabels;

        private showConfirmationModal: boolean = false;
        private value: InsuredDetailsValue | null = null;
        private controller: FormController = buildInsuredDetailsController([]);
        private faChevronLeft = faChevronLeft;
        private faCheckCircle = faCheckCircle;
        private faExclamationTriangle = faExclamationTriangle;
        private undoInfo: {
            insuredId: string;
            insuredFieldKey: string;
            previousValue: any;
            currentValue: any;
        } | null = null;
        private alertTimeout: any = null;
        private startedUpdatingInsured: boolean = false;
        private sortedDetailsList: InsuranceInsuredCoverageDetails[] = [];
        private sortedInsuredCriteriaGroups: InsuranceInsuredCoverageCriteriaGroup[] = [];

        private customComponents = {
            [JsonFormType.boolean]: InsuredDetailsBooleanForm,
            [JsonFormType.string]: InsuredDetailsStringForm,
            [InsuranceSchemaDisplayFormat.address]: InsuredDetailsAddressForm,
            [InsuranceSchemaDisplayFormat.country]: JsonSchemaCountryEnumForm,
        };

        private getCustomComponent = getJsonSchemaCompFunction(this.customComponents);

        private localAlertConfig: ActionableAlertConfig | null = null;

        private statusBarUnhandledEvents = omit(this.$listeners, [
            'grantExceptions', 'removeExceptions', 'open-collateral-mapping-modal', 'open-historic-coi-modal',
        ]);

        private get insuredFieldsOther(): InsuranceInsuredField[] {
            return this.insuredFields.filter((insuredField) =>
                !isCollateralInsuredField(insuredField),
            );
        }

        private get insuredFieldCollaterals(): InsuranceInsuredField | null {
            return this.insuredFields.find((insuredField) =>
                isCollateralInsuredField(insuredField),
            ) || null;
        }

        private get collaterals(): InsuredFieldCollateral[] | undefined {
            const key = this.collateralInsuredFieldKey;
            return this.insured.insuredFields && key
                ? this.insured.insuredFields[key] as InsuredFieldCollateral[]
                : undefined;
        }

        private get fields(): Record<string, Pointer> {
            return this.controller.structure.properties!;
        }

        private get brokerInfo(): BrokerInfo | null {
            const brokerInfoCoverage = this.coverageDetailsList.find(
                (coverageDetails) => coverageDetails.coverageType === 'BROKER_INFO')?.coverage || null;
            return brokerInfoCoverage
                ? {
                    name: brokerInfoCoverage.details.insuranceBrokerAgencyName || '',
                    phoneNumber: brokerInfoCoverage.details.insuranceBrokerAgencyContactPhoneNumber || '',
                    contactName: brokerInfoCoverage.details.insuranceBrokerAgencyContactName || '',
                }
                : null;
        }

        private get deleteAlertTitle(): string {
            return `${startCase(this.undoInfo?.insuredFieldKey || '')} Deleted`;
        }

        private get rpName(): string {
            return this.$rp.current!;
        }

        private get updatingInsured(): boolean {
            return (
                this.updateInsuredsStatus.status === OperationStatus.loading &&
                this.startedUpdatingInsured
            ) || this.currentlyUpdatedInsuredsIds.includes(this.insured.id);
        }

        private get isCollateralsFeaturesEnabled(): boolean {
            return this.isCollateralEnabled && this.collateralEntities.length > 0;
        }

        private get updateInsuredsStatus(): BatchUpdateInsuredsStatus {
            return this.$store.state.insuredManagement.updateInsuredsStatus[this.rpName] || {
                status: OperationStatus.uninitialized,
                successCount: 0,
                failureCount: 0,
                totalCount: 0,
                successes: [],
                failures: [],
            };
        }

        private get patchInsuredsStatus(): PatchInsuredsStatus {
            return this.$store.state.insuredManagement.patchInsuredsStatus[this.rpName] || {
                status: OperationStatus.uninitialized,
                successCount: 0,
                failureCount: 0,
                totalCount: 0,
                successes: [],
                failures: [],
                currentlyUpdated: [],
                isBulk: false,
            };
        }

        private get grantExceptionStatus(): OperationStatus {
            return this.$store.state.insuredDetails.grantExceptionStatus[this.rpName] || OperationStatus.uninitialized;
        }

        private get removeExceptionStatus(): OperationStatus {
            return this.$store.state.insuredDetails.removeExceptionStatus[this.rpName] || OperationStatus.uninitialized;
        }

        private get insuredLevelException(): InsuranceStatusOverride | null {
            const details = this.coverageDetailsList.find(
                (details) => details.complianceStatusMetadata &&
                    details.complianceStatusMetadata.statusOverride &&
                    details.complianceStatusMetadata.statusOverride.level === InsuranceExceptionLevel.insured);
            return details?.complianceStatusMetadata?.statusOverride || null;
        }

        private get effectiveGroupNames(): string[] {
            return this.sortedInsuredCriteriaGroups
                .filter((group) => group.active)
                .map((group) => group.displayName);
        }

        private get alertConfig(): ActionableAlertConfig {
            return this.externalAlertConfig || this.localAlertConfig;
        }

        private get allCoverageTypeEvaluationErrors(): Record<string, Record<string, FieldEvaluationResultError>> {
            return this.sortedDetailsList.reduce((acc, coverageDetails) => {
                if (coverageDetails.coverage) {
                    acc[coverageDetails.coverageType] = mapEvaluationErrors(
                        coverageDetails.evaluationResults ?? [],
                        this.insuredCoverageCriteriaGroups,
                        coverageDetails,
                    );
                } else {
                    acc[coverageDetails.coverageType] = {};
                }

                return acc;
            }, {} as Record<string, Record<string, FieldEvaluationResultError>>);
        }

        private get jsonSchemaElementStrings(): Partial<JsonSchemaFormStrings> {
            return { customLabels: { countryLabels: this.enumLabels.countryCode } };
        }

        @Watch('insured', { immediate: true })
        private onInsuredChange(): void {
            if (!this.value) {
                this.value = {
                    displayName: this.insured.displayName,
                    body: {
                        profile: {
                            contactEmail: this.insured.contactEmail,
                            contactName: this.insured.contactName,
                            contactPhoneNumber: this.insured.contactPhoneNumber,
                        },
                        insuredFields: omit({ ...this.insured.insuredFields }, this.collateralInsuredFieldKey),
                    },
                };
            }
        }

        @Watch('insuredFieldsOther', { immediate: true })
        private onInsuredFieldsChange(): void {
            this.controller = buildInsuredDetailsController(this.insuredFieldsOther);
        }

        @Watch('updateInsuredsStatus', { immediate: true })
        private onUpdateInsuredChange(): void {
            this.showUpdateErrorIfEncountered();
            this.showAlertIfDeleteOnUpdate();
            this.resetUpdatingInsuredStatusIfDone();
        }

        @Watch('patchInsuredsStatus', { immediate: true })
        private onPatchInsuredChange(): void {
            this.showPatchErrorIfEncountered();
            this.showAlertIfDeleteOnPatch();
        }

        @Watch('coverageDetailsList', { immediate: true })
        private onCoverageDetailsChange(): void {
            const coverageDetailsListNew = cloneDeep(sortBy(this.coverageDetailsList, (details) => (
                this.coverageModels.find((model) => model.coverageType === details.coverageType)?.label ||
                details.coverageType
            )));
            coverageDetailsListNew.forEach((details) => {
                details.evaluationResults =
                    details.evaluationResults
                        .map((evaluationResult) => filterOutIsPresentUsedFields(evaluationResult));
            });
            this.sortedDetailsList = coverageDetailsListNew;
        }

        @Watch('insuredCoverageCriteriaGroups', { immediate: true })
        private onInsuredCoverageCriteriaGroupsChange(): void {
            this.sortedInsuredCriteriaGroups =
                sortBy(this.insuredCoverageCriteriaGroups, [ (group) => group.displayName.toLowerCase() ]);
        }

        private onModalAction(confirmed: boolean): void {
            if (confirmed) {
                this.showConfirmationModal = false;
            } else {
                this.$emit('close');
            }
        }

        private onAlertAction(alertName: string) {
            if (alertName === 'undo') {
                this.undoDeletion();
            }
        }

        private onCloseButtonClick(): void {
            if (!this.fields.displayName.valid || !this.fields.body.valid) {
                this.showConfirmationModal = true;
            } else {
                this.checkAndEditInsuredFields();
                this.$emit('close');
            }
        }

        private onDisplayNameChange(): void {
            if (this.value && this.fields.displayName.valid) {
                this.editStandardFieldsIfDiff({ ...this.insured, displayName: this.value.displayName });
            }
        }

        private onBodyChange(): void {
            this.checkAndEditStandardFields();
            this.checkAndEditInsuredFields();
        }

        private onCollateralsChange(collaterals: InsuredFieldCollateral[]): void {
            this.editInsuredFieldsIfDiff({
                ...this.insured,
                insuredFields: concatCollateralsInsuredFields(
                    this.value?.body.insuredFields,
                    collaterals,
                    this.collateralInsuredFieldKey,
                ),
            } as InsuranceInsured);
        }

        private checkAndEditStandardFields(): void {
            if (this.value && this.fields.displayName.valid && this.fields.body.properties!.profile.valid) {
                const profile = this.fields.body.properties!.profile.form.getValue(this.value.body.profile);
                this.editStandardFieldsIfDiff({ ...this.insured, ...profile });
            }
        }

        private checkAndEditInsuredFields(): void {
            if (this.fields.body.properties!.insuredFields?.valid) {
                this.editInsuredFieldsIfDiff({
                    ...this.insured,
                    insuredFields:
                        concatCollateralsInsuredFields(
                            this.value?.body.insuredFields, this.collaterals, this.collateralInsuredFieldKey,
                        ),
                } as InsuranceInsured);
            }
        }

        private editStandardFieldsIfDiff(insured: InsuranceInsured) {
            const insuredToCompare = mapValues(insured, (val: any) => (val === '' ? null : val));
            const diff = getStandardFieldsDiff(this.insured, insuredToCompare);
            if (!isEmpty(diff)) {
                if (editingRequiresPutOperation(this.insured, diff)) {
                    // TODO(PRODUCT-15000): Revisit it when PATCH protocol will support create insured field action.
                    this.updateInsured({ ...this.insured, ...diff });
                    this.startedUpdatingInsured = true;
                } else {
                    this.patchStandardFieldsWithDiff(diff);
                }
            }
        }

        private editInsuredFieldsIfDiff(insured: InsuranceInsured) {
            if (this.insured.insuredFields) {
                const diff = getInsuredFieldsDiff(this.insured.insuredFields, insured.insuredFields || {});
                if (!isEmpty(diff)) {
                    this.updateUndoInfoIfDelete(this.insured, diff);
                    if (editingRequiresPutOperation(this.insured.insuredFields, diff)) {
                        this.updateInsured({
                            ...this.insured, insuredFields:
                                omitBy({ ...this.insured.insuredFields, ...diff }, isNullishOrEmptyValue),
                        });
                        this.startedUpdatingInsured = true;
                    } else {
                        this.patchInsuredFieldsWithDiff(diff);
                    }
                }
            }
        }

        private updateInsured(insured: InsuranceInsured) {
            const insuredToEmit = insured;

            if (insuredToEmit?.insuredFields?.collaterals) {
                insuredToEmit.insuredFields = getInsuredFieldsOmittingCollateralsNullProps(
                    insuredToEmit.insuredFields,
                );
            }
            this.$emit('updateInsureds', [ insuredToEmit ]);
        }

        private patchStandardFieldsWithDiff(diff: Record<string, any>): void {
            const insuredData = [
                Object.entries(diff).reduce((p, [ k, v ]) => {
                    if (k === 'insuredFields') {
                        const oldInsuredFields = getInsuredFieldsOmittingCollateralsNullProps(this.insured[k]);
                        const newInsuredFields = getInsuredFieldsOmittingCollateralsNullProps(v);

                        p[k] = {
                            op: PatchOperationType.update,
                            oldValue: oldInsuredFields,
                            newValue: newInsuredFields,
                        };
                    } else {
                        p[k] = {
                            op: PatchOperationType.update,
                            oldValue: (this.insured as Record<string, any>)[k],
                            newValue: v,
                        };
                    }
                    return p;
                }, { id: this.insured.id } as Record<string, unknown>),
            ];
            this.$emit('patchInsured', insuredData);
        }

        private patchInsuredFieldsWithDiff(diff: Record<string, any>): void {
            const insuredFields = Object
                .entries(omitBy(diff, isNullishOrEmptyValue))
                .reduce((p, [ k, v ]) => {
                            const oldValue = this.insured.insuredFields
                                ? this.insured.insuredFields[k]
                                : undefined;
                            const op = oldValue === undefined
                                ? PatchOperationType.replace
                                : PatchOperationType.update;
                            if (k === this.collateralInsuredFieldKey) {
                                p[k] = {
                                    op,
                                    oldValue: getCollateralsOmittingNullProps(
                                        oldValue as (undefined | InsuredFieldCollateral[]),
                                    ),
                                    newValue: getCollateralsOmittingNullProps(v),
                                };
                            } else {
                                p[k] = {
                                    op,
                                    oldValue,
                                    newValue: v,
                                };
                            }
                            return p;
                        },
                        {} as any,
                );
            this.$emit('patchInsured', [ {
                id: this.insured.id,
                insuredFields,
            } ]);
        }

        private updateUndoInfoIfDelete(insured: InsuranceInsured, diff: Record<string, any>): void {
            Object.entries(diff).forEach(([ key, value ]) => {
                if (isDeletingInsureFieldItem(key, value, insured.insuredFields)) {
                    this.undoInfo = {
                        insuredId: insured.id,
                        insuredFieldKey: key,
                        previousValue: insured.insuredFields![key],
                        currentValue: value,
                    };
                }
            });
        }

        private undoDeletion() {
            if (this.undoInfo) {
                clearTimeout(this.alertTimeout);
                this.alertTimeout = null;
                this.localAlertConfig = null;
                this.$emit('updateInsureds', [ {
                    ...this.insured,
                    insuredFields: {
                        ...this.insured.insuredFields,
                        [this.undoInfo.insuredFieldKey]: this.undoInfo.previousValue,
                    },
                } ]);
                this.undoInfo = null;
            }
        }

        private displayDeleteAlert() {
            if (this.alertTimeout) {
                clearTimeout(this.alertTimeout);
                this.alertTimeout = null;
            }
            this.localAlertConfig = {
                type: 'success',
                name: 'undo',
                title: this.deleteAlertTitle,
                actionTitle: 'UNDO',
                icon: this.faCheckCircle,
            };
            this.alertTimeout = setTimeout(() => {
                this.localAlertConfig = null;
                this.undoInfo = null;
            }, 5000);
        }

        private async showPatchErrorIfEncountered(): Promise<void> {
            if (this.patchInsuredsStatus.failureCount > 0) {
                if (this.patchInsuredsStatus.failures.some((failure) =>
                    // We don't have custom error code for oldValue mismatch, so we need to check error message.
                    failure.error.message.includes('oldValue did not match current value'))) {
                    await this.$store.actions.snackbar.displaySnackbar({
                        message: 'Please reload the page to synchronize data.',
                        permanent: true,
                        success: false,
                    });
                } else {
                    this.setTimedAlert({ type: 'danger', title: `Something went wrong!` });
                }
            }
        }

        private showUpdateErrorIfEncountered(): void {
            if (this.updateInsuredsStatus.status === OperationStatus.success
                && this.updateInsuredsStatus.failures.length > 0) {
                this.setTimedAlert({
                    type: 'danger',
                    title: 'Cannot update the insured, an unknown error occurred.',
                });
            } else if (this.updateInsuredsStatus.status === OperationStatus.error) {
                this.setTimedAlert({
                    type: 'danger',
                    title: 'Sorry, something went wrong during update insured. Please try again later.',
                });
            }
        }

        private showAlertIfDeleteOnPatch(): void {
            if (this.patchInsuredsStatus.successCount > 0 && this.undoInfo) {
                const matchedInsured =
                    this.patchInsuredsStatus.successes.find((x) => x.id === this.undoInfo?.insuredId);
                const newValue = matchedInsured?.insuredFields?.[this.undoInfo?.insuredFieldKey]?.newValue;
                const oldValue = matchedInsured?.insuredFields?.[this.undoInfo?.insuredFieldKey]?.oldValue;
                if (
                    matchedInsured?.insuredFields
                    && isEqual(newValue, this.undoInfo?.currentValue)
                    && isEqual(oldValue, this.undoInfo?.previousValue)
                ) {
                    this.displayDeleteAlert();
                }
            }
        }

        private showAlertIfDeleteOnUpdate(): void {
            if (this.updateInsuredsStatus.status === OperationStatus.success && this.undoInfo) {
                const matchedInsured =
                    this.updateInsuredsStatus.successes.find((x) => x.id === this.undoInfo?.insuredId);
                // since update means add new from nothing or delete to empty. so empty here means delete
                if (matchedInsured && matchedInsured.insuredFields &&
                    !matchedInsured.insuredFields[this.undoInfo.insuredFieldKey]) {
                    this.displayDeleteAlert();
                }
            }
        }

        private resetUpdatingInsuredStatusIfDone(): void {
            if (this.updateInsuredsStatus.successes.some((x) => x.id === this.insured.id) ||
                (this.updateInsuredsStatus.failures as InsuranceInsured[])?.some((x) => x.id === this.insured.id)
            ) {
                this.startedUpdatingInsured = false;
            }
        }

        private hideAlert(): void {
            this.localAlertConfig = null;
            clearTimeout(this.alertTimeout);
        }

        private setTimedAlert(config: ActionableAlertConfig, delay = 5000) {
            this.hideAlert();
            this.localAlertConfig = { ...config };
            this.alertTimeout = setTimeout(() => {
                this.hideAlert();
            }, delay);
        }

        private goToCriterion(coverageCriteriaGroupId: string, coverageType: InsuranceCoverageType): void {
            this.$emit('goToCriterion', coverageCriteriaGroupId, coverageType);
        }

        private finishGrantExceptionProcedure = (modified: boolean, insuredName: string): void => {
            if (!modified) {
                return;
            }
            if (this.grantExceptionStatus === OperationStatus.success) {
                this.setTimedAlert({
                    icon: this.faCheckCircle,
                    type: 'success',
                    title: `Exception granted for ${insuredName}`,
                });
                this.$emit('evaluationUpdated');
            } else {
                this.setTimedAlert({
                    icon: this.faExclamationTriangle,
                    type: 'danger',
                    title: `Failed to grant exception for ${insuredName}`,
                });
            }
        };

        private grantExceptions(exceptions: InsuranceExceptionInput[], coverage?: InsuranceInsuredCoverage | null) {
            /*
            * we could have no coverage passed for 2 cases,
            * insured level or entity exception (collateral) from collateral tab
            * while exceptions can be array, at the moment the only time there will be more than one is when grant all
            * for all non-compliant criteria. in this case all exception are associated with one single coverage type
            * hence it's safe to grab coverage type from the first index of exceptions.
            * */
            let associatedCoverage = coverage;
            if (!associatedCoverage && exceptions[0].coverageType) {
                associatedCoverage =
                    this.coverageDetailsList.find(
                        (detail) => detail.coverageType === exceptions[0].coverageType,
                    )?.coverage;
            }
            this.$procedures.execute('grantExceptionProcedure', {
                exception: {
                    rpName: this.rpName,
                    insured: this.insured,
                    data: exceptions,
                },
                coverage: associatedCoverage,
            }, this.finishGrantExceptionProcedure);
        }

        private finishRemoveExceptionProcedure = (modified: boolean, insuredName: string): void => {
            if (!modified) {
                return;
            }
            if (this.removeExceptionStatus === OperationStatus.success) {
                this.setTimedAlert({
                    icon: this.faCheckCircle,
                    type: 'success',
                    title: `Exception removed for ${insuredName}`,
                });
                this.$emit('evaluationUpdated');
            } else {
                this.setTimedAlert({
                    icon: this.faExclamationTriangle,
                    type: 'danger',
                    title: `Failed to remove exception for ${insuredName}`,
                });
            }
        };

        private removeExceptions(exceptionIds: string[]): void {
            this.$procedures.execute('removeExceptionProcedure', {
                rpName: this.rpName,
                insured: this.insured,
                exceptionIds,
            }, this.finishRemoveExceptionProcedure);
        }

        private openCollateralMappingModal(): void {
            this.$procedures.execute('collateralMappingProcedure', {
                collaterals: this.collateralEntities,
                insuredContactEmail: this.insured.contactEmail,
                insuredId: this.insured.id,
            });
        }

        private openHistoricCoiModal(): void {
            this.$emit('open-historic-coi-modal', this.insured);
        }

        private navigateToCriteriaGroupList(): void {
            this.$router.push({
                name: 'insuredDecisioningCriteria',
                params: {
                    rpId: this.rpName,
                },
            }).catch(noop);
        }

        private navigateToCriteriaGroup(id: string): void {
            this.$router.push({
                name: 'insuredDecisioningCriteriaGroup',
                params: {
                    rpId: this.rpName,
                    id,
                },
            }).catch(noop);
        }

        private destroyed() {
            clearTimeout(this.alertTimeout);
        }
    }
</script>

<template>
    <Modal :additional-class-name="modalClasses" open @close="close">
        <template #header>
            Add or update insured
        </template>
        <Alert v-if="alertConfig" :type="alertConfig.type">
            <template #title>
                <FontAwesomeIcon :icon="faExclamationTriangle" />
                {{ alertConfig ? alertConfig.title : null }}
            </template>
        </Alert>
        <div v-if="form" class="AddIndividualInsuredModal__inputSection">
            <JsonSchemaForm
                ref="formElement"
                v-model="value"
                :form="form"
                :disabled="saving"
                :custom-component-input="getCustomComponent"
                @submit="createInsured"
            />
        </div>

        <div class="AddIndividualInsuredModal__actionSection">
            <Button
                type="primary"
                class="AddIndividualInsuredModal__submitButton"
                :disabled="!valid"
                :loading="saving"
                @click="createInsured"
            >
                Save Insured
            </Button>
        </div>
    </Modal>
</template>

<script lang="ts">
    import { Component, Prop, Vue, Watch, Ref } from '@evidentid/vue-property-decorator';
    import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
    import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
    import {
        InsuranceSchema,
        InsuranceInsuredField,
        InsuranceInsuredInput,
        InsuranceSchemaDisplayFormat,
    } from '@evidentid/rpweb-api-client/types';
    import { Modal } from '@evidentid/dashboard-commons/components/Modal';
    import { FormElement, FormInput } from '@evidentid/dashboard-commons/components/Form';
    import { Button } from '@evidentid/dashboard-commons/components/Button';
    import { Alert } from '@evidentid/dashboard-commons/components/Alert';
    import { AlertType } from '@evidentid/dashboard-commons/components/Alert/types';
    import { JsonSchemaForm } from '@evidentid/dashboard-commons/components/JsonSchemaForm';
    import createForm from '@evidentid/json-schema/createForm';
    import LoadingModal from '@/components/LoadingModal.vue';
    import { buildInsuredJsonSchema } from '@/modules/insured-management/utils/buildInsuredJsonSchema';
    import {
        filterInsuredEmptyItems,
        hasPastExpirationDate,
        trimInsured,
    } from '@/modules/insured-management/utils/insuredProcessing';
    import JsonSchemaConstForm
        from '@evidentid/dashboard-commons/components/JsonSchemaForm/elements/JsonSchemaConstForm.vue';
    import { getJsonSchemaCompFunction } from '@/utils/getJsonSchemaCustomCompFuntion';
    import JsonSchemaCountryEnumForm
        from '@evidentid/dashboard-commons/components/JsonSchemaForm/customElements/JsonSchemaCountryEnumForm.vue';
    import JsonSchemaAddressForm from '@evidentid/dashboard-commons/components/JsonSchemaForm/customElements/JsonSchemaAddressForm.vue';
    import { removeNonStandardSchemaFormat } from '@/modules/decisioning-criteria/utils/removeNonStandardSchemaFormat';

    @Component({
        components: {
            Alert,
            Modal,
            Button,
            FormElement,
            FormInput,
            FontAwesomeIcon,
            JsonSchemaForm,
            LoadingModal,
        },
    })
    export default class AddIndividualInsuredModal extends Vue {
        @Prop({ type: Array, default: () => [] })
        private insuredFields!: InsuranceInsuredField[];

        @Prop({ type: Array, default: () => [] })
        private coverageTypes!: string[];

        @Prop({ type: Object, default: null })
        private alertConfig!: { type: AlertType, title: string } | null;

        @Prop({ type: Boolean, default: false })
        private saving!: boolean;

        @Ref()
        private formElement!: JsonSchemaForm;

        private faExclamationTriangle = faExclamationTriangle;

        private form = null as any;
        private value: InsuranceInsuredInput = null as any;

        private customComponents = {
            [InsuranceSchemaDisplayFormat.hidden]: JsonSchemaConstForm,
            [InsuranceSchemaDisplayFormat.address]: JsonSchemaAddressForm,
            [InsuranceSchemaDisplayFormat.country]: JsonSchemaCountryEnumForm,
        };
        private getCustomComponent = getJsonSchemaCompFunction(this.customComponents);

        private get valid(): boolean {
            return this.form?.isValid(filterInsuredEmptyItems(this.value, this.insuredFields), true);
        }

        @Watch('insuredFields', { immediate: true })
        private onInsuredFieldsUpdate(): void {
            const sortedCoverageTypes = [ ...this.coverageTypes ].sort();
            this.form = createForm(removeNonStandardSchemaFormat(buildInsuredJsonSchema(
                this.insuredFields,
                sortedCoverageTypes,
                [ 'required', (insured) => insured.name.toLowerCase() ],
                [ 'desc', 'asc' ],
            )));
            this.value = this.form.getValue(undefined, true);
            this.setAddressInsuredFieldsDefaultCountryValue();
        }

        private get modalClasses(): string {
            return this.saving ? 'AddIndividualInsuredModal AddIndividualInsuredModal--saving' : 'AddIndividualInsuredModal';
        }

        private close(): void {
            this.$emit('close');
        }

        private createInsured(): void {
            if (this.valid) {
                const insured =
                    filterInsuredEmptyItems(trimInsured(this.value), this.insuredFields);
                this.$emit('submit', this.form.getValue(insured, true), hasPastExpirationDate(this.value));
            } else {
                // Do it after the form renders errors
                this.formElement.scrollToFirstError(true);
            }
        }

        private setAddressInsuredFieldsDefaultCountryValue(): void {
            if (this.value.insuredFields) {
                const insuredFieldsValue = this.value.insuredFields;
                const isAddress = (field: InsuranceInsuredField) =>
                    (field.schema as InsuranceSchema).displayFormat === InsuranceSchemaDisplayFormat.address;
                const addressFieldKeys = this.insuredFields.filter(isAddress).map((field) => field.key);
                addressFieldKeys.forEach((key: string) => {
                    const field = insuredFieldsValue[key];
                    if (field && typeof field === 'object' && 'country' in field && !field.country) {
                        field.country = 'US';
                    }
                });
            }
        }
    }
</script>

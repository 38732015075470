<template>
    <div class="InsuredsTooltipDetails">
        <div class="InsuredsTooltipDetails__insuredName">
            <label>Insured Name</label>
            <span>{{ insured.displayName }}</span>
        </div>
        <div class="InsuredsTooltipDetails__contactName">
            <label>Primary Contact Name</label>
            <span>{{ insured.contactName }}</span>
        </div>
        <div class="InsuredsTooltipDetails__contactEmail">
            <label>Primary Contact Email</label>
            <span>{{ insured.contactEmail }}</span>
        </div>
        <div class="InsuredsTooltipDetails__contactPhoneNumber">
            <label>Primary Contact Phone Number</label>
            <span>{{ formattedPhoneNumber }}</span>
        </div>
    </div>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';
    import { InsuranceInsured } from '@evidentid/rpweb-api-client/types';
    import 'intl-tel-input/build/js/utils';

    @Component
    export default class InsuredsTooltipDetails extends Vue {
        @Prop({ type: Object })
        private insured!: InsuranceInsured;

        private get formattedPhoneNumber() {
            // @ts-ignore:
            return window.intlTelInputUtils.formatNumber(
                this.insured.contactPhoneNumber,
                null,
                window.intlTelInputUtils.numberFormat.NATIONAL,
            );
        }
    }
</script>

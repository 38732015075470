import startCase from 'lodash/startCase';
import { InsuranceCoverageModel } from '@evidentid/rpweb-api-client/types';
import { InsuranceCoverageType } from '@evidentid/insurance-facing-lib/models/insured-details';

const attributeAndCoverageTypeMap: Record<string, InsuranceCoverageType> = {
    '3rd_party_fidelity_bond': InsuranceCoverageType.thirdPartyFidelityBond,
    'automobile_liability': InsuranceCoverageType.businessAutoLiability,
    'aviation_liability': InsuranceCoverageType.aviationLiability,
    'bailees_insurance': InsuranceCoverageType.baileesInsurance,
    'boatowners': InsuranceCoverageType.boatowners,
    'builders_risk': InsuranceCoverageType.buildersRisk,
    'cargo_liability': InsuranceCoverageType.cargoLiability,
    'collective_personal_accident_insurance': InsuranceCoverageType.collectivePersonalAccidentInsurance,
    'commercial_auto_liability': InsuranceCoverageType.businessAutoLiability,
    'commercial_auto_physical_damage': InsuranceCoverageType.commercialAutoPhysicalDamage,
    'commercial_crime': InsuranceCoverageType.commercialCrimeLiability,
    'commercial_general_liability': InsuranceCoverageType.commercialGeneralLiability,
    'commercial_property': InsuranceCoverageType.commercialProperty,
    'commercial_trailer_interchange': InsuranceCoverageType.commercialTrailerInterchange,
    'comprehensive_crime': InsuranceCoverageType.commercialCrimeLiability,
    'contingent_auto_liability': InsuranceCoverageType.contingentAutoLiability,
    'contingent_cargo_liability': InsuranceCoverageType.contingentCargoLiability,
    'crop_insurance': InsuranceCoverageType.cropInsurance,
    'cyber_liability': InsuranceCoverageType.cyberLiability,
    'dwelling': InsuranceCoverageType.dwelling,
    'employee_dishonesty': InsuranceCoverageType.employeeDishonesty,
    'employers_liability': InsuranceCoverageType.employersLiability,
    'employment_practices_liability': InsuranceCoverageType.employmentPracticesLiability,
    'environmental_liability': InsuranceCoverageType.environmentalLiability,
    'farmowners': InsuranceCoverageType.farmowners,
    'fleet': InsuranceCoverageType.fleet,
    'flood_insurance': InsuranceCoverageType.floodInsurance,
    'garage_keepers_liability': InsuranceCoverageType.garageKeepersLiability,
    'garage_liability': InsuranceCoverageType.garageLiability,
    'hold_harmless_agreement': InsuranceCoverageType.holdHarmlessAgreement,
    'homeowners': InsuranceCoverageType.homeowners,
    'hull_and_machinery': InsuranceCoverageType.hullAndMachinery,
    'inland_marine': InsuranceCoverageType.inlandMarineCommercialAuto,
    'inland_marine_commercial_auto': InsuranceCoverageType.inlandMarineCommercialAuto,
    'leased_or_rented_equipment': InsuranceCoverageType.leasedOrRentedEquipment,
    'life_insurance': InsuranceCoverageType.lifeInsurance,
    'longshore_and_harbor_workers_compensation': InsuranceCoverageType.longshoreAndHarborWorkersCompensation,
    'loss_of_income': InsuranceCoverageType.lossOfIncome,
    'marine_general_liability': InsuranceCoverageType.marineGeneralLiability,
    'maritime_employers_liability': InsuranceCoverageType.maritimeEmployersLiability,
    'media_liability': InsuranceCoverageType.mediaLiability,
    'medical_errors_and_omissions': InsuranceCoverageType.medicalErrorsAndOmissions,
    'non_trucking_liability': InsuranceCoverageType.nonTruckingLiability,
    'occupational_accident': InsuranceCoverageType.occupationalAccident,
    'personal_liability': InsuranceCoverageType.personalLiability,
    'private_passenger_auto': InsuranceCoverageType.privatePassengerAuto,
    'products_and_completed_operations': InsuranceCoverageType.productsAndCompletedOperations,
    'professional_liability': InsuranceCoverageType.professionalLiability,
    'property_insurance': InsuranceCoverageType.commercialProperty,
    'protection_and_indemnity': InsuranceCoverageType.protectionAndIndemnity,
    'public_and_products_liability': InsuranceCoverageType.publicAndProductsLiability,
    'riggers_liability': InsuranceCoverageType.riggersLiability,
    'sexual_abuse_and_molestation': InsuranceCoverageType.sexualAbuseAndMolestationLiability,
    'student_accident_medical': InsuranceCoverageType.studentAccidentMedical,
    'surety_bond': InsuranceCoverageType.suretyBond,
    'technology_liability': InsuranceCoverageType.technologyLiability,
    'umbrella_excess_liability': InsuranceCoverageType.umbrellaExcessLiability,
    'umbrella_liability': InsuranceCoverageType.umbrellaExcessLiability,
    'w_9_form': InsuranceCoverageType.w9Form,
    'warehouse_legal_liability': InsuranceCoverageType.warehouseLegalLiability,
    'watercraft': InsuranceCoverageType.watercraft,
    'workers_compensation': InsuranceCoverageType.workersCompensation,
    'yacht_insurance': InsuranceCoverageType.yachtInsurance,
};

export function getCoverageTypeVersionByAttribute(attr: string): string | null {
    const version = attr.split('.')[4];
    return /v\d+/.test(version) ? version : null;
}

export function getCoverageTypeByAttribute(attr: string): InsuranceCoverageType | null {
    const typePortion = attr.split('.')[3];
    return attributeAndCoverageTypeMap[typePortion] || null;
}

export function getCoverageTypeLabelByAttribute(
    attr: string,
    withVersion?: boolean,
    coverageTypeModels?: InsuranceCoverageModel[],
): InsuranceCoverageType | string | null {
    const coverageType = getCoverageTypeByAttribute(attr);
    if (!coverageType) {
        return null;
    }
    const typeModel = coverageTypeModels && coverageTypeModels.length > 0
        ? coverageTypeModels.find((model) => model.coverageType === coverageType)
        : null;
    const typeLabel = typeModel?.label || startCase(coverageType.toLowerCase());
    const version = getCoverageTypeVersionByAttribute(attr);
    return withVersion && version
        ? `${typeLabel} ${version}`
        : typeLabel;
}

import { RulesLogic } from 'json-logic-js';
import JsonSchema from '@evidentid/json-schema/interfaces/JsonSchema';
import type { AsaStatusType } from '@evidentid/ido-lib/interfaces/attributeStatuses';
import type { RelyingPartyRequestStatusType } from '@evidentid/ido-lib/getIdoRequestStatus';
import {
    InsuranceComplianceStatus,
    InsuranceComplianceStatusMetadata,
    InsuranceCoverageType,
    InsuranceEvaluationResult,
    InsuranceExceptionLevel,
} from '@evidentid/insurance-facing-lib/models/insured-details';
import { InsuredFieldAddress } from '@evidentid/insurance-facing-lib/models/dashboard';
import { PatchOperationType } from './index';
import { InsuredFieldCollateral } from './models/InsuredFieldCollateral.model';

export interface RelyingPartySignature {
    name: string;
    displayName: string;
    externalConfigurationEnabled?: boolean; // Is the RP (Insurance) Configuration enabled?
}

export enum OperationError {
    keyConflict = 'KeyConflict',
    notFound = 'NotFound',
    badRequest = 'BadRequest',
    badRoute = 'BadRoute',
    unauthorized = 'Unauthorized',
    unknown = 'Unknown',
}

export interface RelyingPartyConsentData {
    data: string;
    metadata: Record<string, string>;
    // eslint-disable-next-line camelcase
    mime_type: string;
}

export interface RelyingPartySettings {
    branding: {
        dbaName: string;
        logoUrl: string;
        primaryColor: string;
        wordmarkUrl: string;
    };
    email: {
        barBelowWordmarkBgColor: string;
        boxBehindButtonBgColor: string;
        boxBehindButtonFgColor: string;
        buttonBgColor: string;
        buttonFgColor: string;
    };
    customAuthErrorStrings: any;
    fcraHtml: any;
    idoConfirmationMessage: any;
    idoStatusMessages: any;
    postHooks: any;
    requestTimeoutSettings: any;
}

export interface RelyingPartyFilters {
    dateSortAsc?: boolean;
    seqId?: number;
    limit?: number;
    startDate?: number;
    endDate?: number;
    textSearchPattern?: string;
    sentBy?: string;
    summary?: string;
}

export interface RelyingPartySearchAsa {
    attributeSource: string;
    attributeType: string;
    exp: number;
    iat: number;
    id: string;
    iss: string;
    stage: AsaStatusType;
    status: string;
    statusDetail: string | null;
    sub: string;
    subscription: boolean;
    updateFrequencyDays: null;
}

export interface AttributeData {
    displayName: string | null;
    status: string;
    type: string;
    value: any;
}

export interface RelyingPartyRequestDetails {
    id: string;
    description: string;
    summary: string;
    completedAt: number;
    createdAt: number;
    createdBy: string;
    idOwner: {
        domain: string;
        email: string;
        id: string;
    };
    notes: string;
    relyingPartyId: string;
    seqId: number;
    status: RelyingPartyRequestStatusType;
    templateId: string | null;
    attributeSharingAgreements: RelyingPartySearchAsa[];
    attributes: AttributeData[];
}

export interface RelyingPartySearchRequest {
    id: string;
    description: string;
    summary: string;
    completedAt: number;
    createdAt: number;
    createdBy: string;
    idOwner: {
        domain: string;
        email: string;
        id: string;
    };
    idOwnerId: string;
    notes: string;
    recipientEmail: string;
    relyingPartyId: string;
    seqId: number;
    stage: AsaStatusType;
    status: RelyingPartyRequestStatusType;
    statusDetail: string | null;
    templateId: string | null;
    attributeSharingAgreements: RelyingPartySearchAsa[];
}

export interface RelyingPartySearchResult {
    requests: RelyingPartySearchRequest[];
}

export interface AttributeDefinition {
    attributePath: string;
    category: string;
    group: string;
    mtime: {
        $objectType: 'utctime';
        timestamp: number;
    };
    readableAttributeName: string;
    value: string;
}

export interface AttributeDefinitionList {
    attributeTypes: AttributeDefinition[];
}

export enum InsuranceComplianceStatusLabel {
    new = 'New',
    pending = 'Pending',
    compliant = 'Compliant',
    nonCompliant = 'Non-Compliant',
}

export enum InsuranceVerificationStatus {
    actionsReview = 'ACTIONS_REVIEW',
    audit = 'AUDIT',
    complete = 'COMPLETE',
    exhausted = 'EXHAUSTED',
    memberOutreach = 'MEMBER_OUTREACH',
    noInsuredResponse = 'NO_INSURED_RESPONSE',
    pendingInsuredSubmission = 'PENDING_INSURED_SUBMISSION',
    processing = 'PROCESSING',
    producerOutreach = 'PRODUCER_OUTREACH',
}

export enum InsuranceVerificationStatusLabel {
    actionsReview = 'Actions Review',
    audit = 'Audit',
    complete = '',
    exhausted = 'Exhausted',
    memberOutreach = 'Member Outreach',
    noInsuredResponse = 'No Insured Response',
    pendingInsuredSubmission = 'Pending Insured Submission',
    processing = 'Processing',
    producerOutreach = 'Producer Outreach',
}

export interface InsuranceInsuredField {
    id: string;
    key: string;
    name: string;
    required: boolean;
    description: string | null;
    schema: JsonSchema;
    createdAt: string;
}

export interface InsuranceInsuredException {
    coverageType: InsuranceCoverageType;
    until: string | null;
    status?: InsuranceComplianceStatus | null;
}

export interface RpUsedAttributesData {
    coverageTypes: { coverageType: InsuranceCoverageType | 'BROKER_INFO', attributes: string[] }[];
}

export enum InsuranceSchemaDisplayFormat {
    checkbox = 'CHECKBOX',
    collateral = 'COLLATERAL',
    collaterals = 'COLLATERALS',
    currency = 'CURRENCY',
    days = 'DAYS',
    months = 'MONTHS',
    hidden = 'HIDDEN',
    insuredField = 'INSURED_FIELD',
    country = 'COUNTRY',
    address = 'ADDRESS',
}

export type InsuranceInsuredFieldType =
    string | number | boolean | object | undefined | string[] | InsuredFieldCollateral[] | InsuredFieldAddress;

export type InsuranceInsuredFieldValue = Record<string, InsuranceInsuredFieldType>;

export interface InsuranceInsuredBase {
    displayName: string;
    contactEmail: string;
    contactName?: string;
    contactPhoneNumber?: string | null;
    insuredFields?: InsuranceInsuredFieldValue;
}

export interface InsuranceInsuredInput extends InsuranceInsuredBase {
    exceptions?: Record<string, string>;
}

export interface InsuranceInsuredInputDataModel extends InsuranceInsuredBase {
    exceptions?: InsuranceInsuredException[];
}

export interface InsuranceComplianceStatusException {
    category: InsuranceExceptionLevel;
    type: string;
    id: string;
}

export interface InsuranceInsuredEffectiveGroup {
    id: string;
    displayName: string;
    complianceStatus: InsuranceComplianceStatus;
}

export interface InsuranceInsured {
    id: string;
    displayName: string;
    contactName: string;
    contactEmail: string;
    contactPhoneNumber: string;
    nextExpiration: string | null;
    complianceStatus: InsuranceComplianceStatus;
    complianceStatusMetadata: InsuranceComplianceStatusMetadata | null;
    verificationStatus: InsuranceVerificationStatus;
    createdAt: string;
    active: boolean;
    paused: boolean;
    insuredFields: InsuranceInsuredFieldValue | null;
    effectiveGroup: InsuranceInsuredEffectiveGroup[] | null;
}

export interface InsuranceAddInsuredsFailure {
    element: InsuranceInsuredInputDataModel;
    error: {
        message: string;
        error: OperationError;
    };
}

export interface InsuranceInsuredPostResponse {
    successCount: number;
    failureCount: number;
    totalCount: number;
    successes: InsuranceInsured[];
    failures: InsuranceAddInsuredsFailure[];
}

export interface InsuranceInsuredUpsertResponse {
    successCount: number;
    failureCount: number;
    totalCount: number;
    successes: InsuranceInsured[];
    failures: (InsuranceInsuredInputDataModel | InsuranceInsured)[];
}

export interface Change<T> {
    oldValue: T;
    newValue: T;
}

export interface InsuredPatchResult {
    id: string;
    displayName?: Change<string>;
    contactName?: Change<string>;
    contactEmail?: Change<string>;
    contactPhoneNumber?: Change<string>;
    nextExpiration?: Change<string>;
    complianceStatus?: Change<InsuranceComplianceStatus>;
    verificationStatus?: Change<InsuranceVerificationStatus>;
    active?: Change<boolean>;
    paused?: Change<boolean>;
    insuredFields?: Record<string, Change<InsuranceInsuredFieldType>>;
}

export interface InsuranceInsuredPatchResponse {
    successCount: number;
    failureCount: number;
    totalCount: number;
    successes: InsuredPatchResult[];
    failures: { element: InsuredPatchResult, error: { message: string, error: string } }[];
}

export interface InsuranceInsuredStatistics {
    compliance: {
        forcePlaced: number;
        compliant: number;
        nonCompliant: number;
        pending: number;
        new: number;
    };
    expiration: {
        within30Days: number;
    };
}

export interface InsuranceDashboardConfiguration {
    insuredFields: string[];
}

export interface InsuranceSendCoiRequestSuccess {
    insuredId: string;
    verificationRequest: any;
}

export interface InsuranceSendCoiRequestResponse {
    totalCount: number;
    successCount: number;
    failureCount: number;
    successes: InsuranceSendCoiRequestSuccess[];
    failures: string[];
}

export interface InsuranceCarrier {
    naicNumber: string | null;
    name: string | null;
    amBestFinancialStrengthRating: string | null;
    amBestFinancialSizeCategory: number | null;
}

export interface InsurancePolicy {
    carrier: InsuranceCarrier;
    insured: string;
    policyNumber: string;
    currency: string;
    effectiveDate: string;
    expirationDate: string;
}

export enum InsuranceAdditionalInsuredEndorsementType {
    unknown = 'UNKNOWN',
    additionalInsured = 'ADDITIONAL_INSURED',
}

export interface InsuranceAdditionalInsuredEndorsementDetails {
    additionalInsuredType: string;
    additionalInsuredName: string;
}

export interface InsuranceAdditionalInsuredEndorsement {
    endorsementType: InsuranceAdditionalInsuredEndorsementType;
    formNumber: string;
    policyNumber: string;
    details: InsuranceAdditionalInsuredEndorsementDetails | null;
}

export interface InsuranceInsuredCoverage {
    id: string;
    coverageType: InsuranceCoverageType;
    policy: InsurancePolicy;
    requestId: string;
    details: any;
    createdAt: string;
    modifiedAt: string;
    complianceStatus: InsuranceComplianceStatus;
    endorsements: InsuranceAdditionalInsuredEndorsement[];
}

export type NonExtractionResults = Record<string, {
    acceptedRequirements: string[];
    rejectedRequirements: string[];
}>;

export enum DeclineReason {
    Declined = 'DECLINED',
    ExceptionRequested = 'EXCEPTION_REQUESTED',
    Missing = 'MISSING',
    NotRenewed = 'NOT_RENEWED'
}

export interface InsuranceInsuredCoverageDetails {
    coverageType: InsuranceCoverageType;
    complianceStatus: InsuranceComplianceStatus | null;
    complianceStatusMetadata: InsuranceComplianceStatusMetadata | null;
    verificationStatus: InsuranceVerificationStatus;
    coverage: InsuranceInsuredCoverage | null;
    evaluationResults: InsuranceEvaluationResult[];
    nonExtraction?: NonExtractionResults;
    declineReason?: DeclineReason;
}

export interface InsuranceEvaluationRule {
    coverageTypes: InsuranceCoverageType[];
}

export enum InsuranceCoverageCriteriaGroupType {
    default = 'DEFAULT',
    collateral = 'COLLATERAL',
}

export interface InsuranceCoverageCriteriaGroupInput {
    displayName: string;
    active: boolean;
    ccgType: InsuranceCoverageCriteriaGroupType;
    numberOfCoverageTypes: number | null;
    numberOfInsuredsAssigned: number | null;
    assignmentRule: RulesLogic | null;
    countryCode: string | null;
    evaluationRule?: {
        coverageTypes: InsuranceCoverageType[];
    };
}

export interface InsuranceCoverageCriteriaGroup extends InsuranceCoverageCriteriaGroupInput {
    id: string;
    countryCode: string;
}

export interface InsuranceInsuredCoverageCriteriaGroup {
    id: string;
    countryCode: string;
    displayName: string;
    active: boolean;
    coverageTypes: InsuranceCoverageType[];
    complianceStatus: InsuranceComplianceStatus;
    evaluationRule: InsuranceEvaluationRule | null;
}

export type InsuranceEffectiveGroup = string[];

export type InsuranceSchema = JsonSchema & { displayFormat?: InsuranceSchemaDisplayFormat };

export interface InsuranceCoverageCriterionField {
    value: any;
    schema: InsuranceSchema;
}

export interface InsuranceCoverageCriterionField {
    value: any;
    schema: InsuranceSchema;
}

export interface InsuranceCoverageCriterionEvaluator {
    references: Record<string, InsuranceCoverageCriterionField>;
}

export enum InsuranceCriteriaCondition {
    equals = 'Equals',
    greaterThan = 'Greater than',
    greaterThanOrEqualTo = 'Greater than or equal to',
    lessThanOrEqualTo = 'less than or equal to',
    lessThan = 'less than',
}

interface InsuranceCriteriaEnumValues {
    reference: string;
    values: Record<string, string>;
}

export interface ConditionalValueReference {
    flagValueReference: string;
    flagEnabledReference: string;
    flagDisabledReference: string;
}

export interface InsuranceCriteriaDisplayMeta {
    title: string;
    condition: InsuranceCriteriaCondition;
    requiredValueReference?: string;
    conditionalValueReference?: ConditionalValueReference | null;
    nonInputCriterionText?: string;
    informationText?: string;
    enumValues?: InsuranceCriteriaEnumValues[];
    verifyToggleDisabled?: boolean;
}

export interface InsuranceCoverageCriterionBase {
    coverageType: InsuranceCoverageType;
    field: string;
    evaluator: InsuranceCoverageCriterionEvaluator;
    verify: boolean;
    emailMessage: string;
    deprecated: boolean;
}

export interface InsuranceCoverageCriterionInput extends InsuranceCoverageCriterionBase {
    displayMetadata?: InsuranceCriteriaDisplayMeta;
}

export interface InsuranceCoverageCriterionTemplate extends InsuranceCoverageCriterionBase {
    default: boolean;
    displayMetadata: InsuranceCriteriaDisplayMeta;
}

export interface InsuranceCoverageCriterion extends InsuranceCoverageCriterionBase {
    id: string;
}

export interface InsuranceCoverageModel {
    coverageType: InsuranceCoverageType;
    version: number;
    schema: any;
    label: string;
}

export interface InsuranceCoverageCriterionMessage {
    nonComplianceMessage: string;
}

export interface PatchInsuranceCriterionUpdate<T> {
    op: PatchOperationType.update;
    id: string;
    value: T;
}

export interface PatchInsuranceCriterionCreate<T> {
    op: PatchOperationType.create;
    value: T;
}

export interface PatchInsuranceCriterionDelete {
    op: PatchOperationType.delete;
    id: string;
}

export type InsuranceCriterionPatch =
    PatchInsuranceCriterionUpdate<InsuranceCoverageCriterion>
    | PatchInsuranceCriterionCreate<InsuranceCoverageCriterionInput>
    | PatchInsuranceCriterionDelete;

export interface InsuranceVerificationRequest {
    verificationId: string;
    requestId: string;
    insuredId: string;
    createdAt: string;
    completedAt: string | null;
    inputReceivedAt: string | null;
    reasons: { verificationType: InsuranceComplianceStatus, coverageType: InsuranceCoverageType }[];
    cancelledCoverageTypes: InsuranceCoverageType[];
    extractedCoverageTypes: InsuranceCoverageType[];
    rprSummary: string | null;
    rprDescription: string | null;
    verificationType: string;
    parentId: string | null;
    verificationRequestStatus: string;
    createdAutomatically: boolean;
    timeoutAt: string | null;
    issuedAt: string;
    declinedCoverageTypes: Record<InsuranceCoverageType, DeclineReason>;
}

export type InsuranceCoverageTypesPerCountry = Record<string, { coverageTypes: InsuranceCoverageType[] }>;

export interface InsuranceConfig {
    distinguishedName: string;
    enrolledAt: string; // ISO Date
    displayName: string;
    commonName: string;
    certificateHolderAddress: string | null; // HTML
}

export type InsuranceConfigInput = Omit<InsuranceConfig, 'commonName' | 'distinguishedName' | 'enrolledAt'>;

export interface InsuranceVerificationRequestConfiguration {
    summaryTemplate: string;
    descriptionTemplate: string;
    initEnabled: boolean;
    remindersEnabled: boolean;
}

export enum VerificationRequestType {
    expiring = 'EXPIRING',
    new = 'NEW',
    nonCompliant = 'NON_COMPLIANT',
}

export interface InsuranceRequestsConfig {
    collateralsEvaluationEnabled: boolean;
    enableEvaluationEngine: boolean;
    expirationNumberOfDays: number;
    notificationTemplateId: string; // UUID
    verificationDataSource: string; // FIXME: Enum?
    verificationRequestConfigurations: Record<VerificationRequestType, InsuranceVerificationRequestConfiguration>;
    coiRequirementsLink: string | null;
    fulfillmentUrl: string | null;
}

interface EnumLabelObjectBase {
    token: string; // enum
    label: string;
}

export interface CoverageTypeCountrySpecificEnumLabel extends EnumLabelObjectBase {
    perCountryLabels: Record<string, string>;
}

export type EnumLabelObject = EnumLabelObjectBase | CoverageTypeCountrySpecificEnumLabel;

// A key is an EnumCategories example payload { countryCode: [{token: 'US', label: 'United States'}]}
export type RawCategorizedEnumLabels = Record<string, EnumLabelObject[]>;

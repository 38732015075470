import uniqBy from 'lodash/uniqBy';
import omit from 'lodash/omit';
import RpWebApiClient from '@evidentid/rpweb-api-client';
import { createStateFactory } from '@evidentid/vue-commons/store';
import { getPersistingErrorActions } from '@evidentid/dashboard-commons/modules/persisting-error';
import {
    InsuranceDashboardConfiguration,
    InsuranceInsured,
    InsuranceInsuredField,
    InsuranceInsuredCoverage,
    InsuranceInsuredStatistics,
    InsuredPatchResult,
    InsuranceVerificationRequest,
    InsuranceRequestsConfig,
    RawCategorizedEnumLabels,
    EnumLabelObject,
} from '@evidentid/rpweb-api-client/types';
import { OperationStatus } from '@evidentid/vue-commons/store/OperationStatus';
import { InsuredFilters } from '@/modules/insured-filtering/types';
import { CategorizedEnumLabels } from '@/modules/dashboard/models/CategorizedEnumLabels.model';
import mapValues from 'lodash/mapValues';

export interface DashboardRequirements {
    rpweb: RpWebApiClient;
}

const dashboardConfig = {
    recordsPerPage: 25,
};

const createState = createStateFactory<DashboardRequirements>();

interface InsuredsListStatus {
    loading: boolean;
    finished: boolean;
    count: number;
    list: InsuranceInsured[];
}

interface InsuredFieldsListStatus {
    status: OperationStatus;
    list: InsuranceInsuredField[];
}

interface CoverageTypeListStatus {
    status: OperationStatus;
    list: string[];
}

interface InsuredStatisticsStatus {
    status: OperationStatus;
    statistics: InsuranceInsuredStatistics;
}

interface InsuranceDashboardConfigurationStatus {
    status: OperationStatus;
    configuration: InsuranceDashboardConfiguration;
}

interface InsuranceCoveragesStatus {
    status: OperationStatus;
    coverages: InsuranceInsuredCoverage[];
}

interface InsuranceRequestStatus {
    status: OperationStatus;
    requests: InsuranceVerificationRequest[];
}

interface InsuranceRequestsConfigStatus {
    status: OperationStatus;
    data: InsuranceRequestsConfig | null;
}

interface InsuranceRequests {
    requests: Record<string, InsuranceRequestStatus>;
}

interface InsuranceSubmissionLink {
    status: OperationStatus;
    submissionLink: string;
}

interface InsuranceSubmissionLinks {
    submissionLinks: Record<string, InsuranceSubmissionLink>;
}

export interface DashboardState {
    insureds: Record<string, InsuredsListStatus>;
    insuredFields: Record<string, InsuredFieldsListStatus>;
    insuredStatistics: Record<string, InsuredStatisticsStatus>;
    coverageTypes: Record<string, CoverageTypeListStatus>;
    dashboardConfiguration: Record<string, InsuranceDashboardConfigurationStatus>;
    coverages: Record<string, InsuranceCoveragesStatus>;
    requests: Record<string, InsuranceRequests>;
    requestsConfig: Record<string, InsuranceRequestsConfigStatus>;
    submissionLinks: Record<string, InsuranceSubmissionLinks>;
    categorizedEnumLabels: CategorizedEnumLabels;
}

export function createEmptyListStatus(): InsuredsListStatus {
    return {
        count: Infinity,
        finished: false,
        loading: false,
        list: [],
    };
}

export function createEmptyInsuredFieldStatus(): InsuredFieldsListStatus {
    return {
        status: OperationStatus.uninitialized,
        list: [],
    };
}

export function createEmptyCoverageTypesStatus(): CoverageTypeListStatus {
    return {
        status: OperationStatus.uninitialized,
        list: [],
    };
}

export function createEmptyInsuredStatisticsStatus(): InsuredStatisticsStatus {
    return {
        status: OperationStatus.uninitialized,
        statistics: {
            compliance: {
                forcePlaced: 0,
                compliant: 0,
                nonCompliant: 0,
                pending: 0,
                new: 0,
            },
            expiration: {
                within30Days: 0,
            },
        },
    };
}

export function createEmptyInsuranceDashboardStatus(): InsuranceDashboardConfigurationStatus {
    return {
        status: OperationStatus.uninitialized,
        configuration: {
            insuredFields: [],
        },
    };
}

export function createEmptyCoveragesInfoStatus(): InsuranceCoveragesStatus {
    return {
        status: OperationStatus.uninitialized,
        coverages: [],
    };
}

export function createEmptyRequestStatus(): InsuranceRequestStatus {
    return {
        status: OperationStatus.uninitialized,
        requests: [],
    };
}

export function createEmptyRequestsConfigStatus(): InsuranceRequestsConfigStatus {
    return {
        status: OperationStatus.uninitialized,
        data: null,
    };
}

export function createEmptySubmissionLinkStatus(): InsuranceSubmissionLink {
    return {
        status: OperationStatus.uninitialized,
        submissionLink: '',
    };
}

const { instantiateState, createMutationsFactories } = createState<DashboardState>(() => ({
    insureds: {},
    insuredFields: {},
    insuredStatistics: {},
    coverageTypes: {},
    dashboardConfiguration: {},
    coverages: {},
    requests: {},
    requestsConfig: {},
    submissionLinks: {},
    categorizedEnumLabels: {},
}));

const { instantiateMutations, createActionFactories } = createMutationsFactories(() => ({
    clearInsuredsList(rpName: string) {
        this.insureds = omit(this.insureds, [ rpName ]);
    },

    clearInsuredFieldsList(rpName: string) {
        // FIXME: remove this once we have a nav guard that block nav until a component is destoryed
        if (this.insuredFields[rpName]?.status === OperationStatus.loading) {
            return;
        }
        this.insuredFields = omit(this.insuredFields, [ rpName ]);
    },

    clearInsuredStatistics(rpName: string) {
        this.insuredStatistics = omit(this.insuredStatistics, [ rpName ]);
    },

    clearCoverageTypes(rpName: string) {
        this.coverageTypes = omit(this.coverageTypes, [ rpName ]);
    },

    clearRequests(rpName: string) {
        this.requests = omit(this.requests, [ rpName ]);
    },

    clearRequestsConfig(rpName: string) {
        this.requestsConfig = omit(this.requestsConfig, [ rpName ]);
    },

    clearSubmissionLinks(rpName: string) {
        this.submissionLinks = omit(this.submissionLinks, [ rpName ]);
    },

    startInsuredsListUpdate(payload: { rpName: string }) {
        this.insureds = {
            ...this.insureds,
            [payload.rpName]: {
                count: Infinity,
                finished: false,
                list: [],
                ...(this.insureds[payload.rpName] as InsuredsListStatus | undefined),
                loading: true,
            },
        } as DashboardState['insureds'];
    },

    pushToInsuredsList(
        payload: { rpName: string, insureds: InsuranceInsured[], count?: number, finished?: boolean }) {
        const prevResult: InsuredsListStatus = this.insureds[payload.rpName] || createEmptyListStatus();
        const insuredsCopy = [ ...prevResult.list ];
        payload.insureds.forEach(((newInsured) => {
            const index = insuredsCopy.findIndex(
                (insuredsCopy) => newInsured.id === insuredsCopy.id,
            );
            if (index > -1) {
                insuredsCopy[index] = newInsured;
            } else {
                insuredsCopy.push(newInsured);
            }
        }));
        const nextResult: InsuredsListStatus = {
            loading: false,
            count: payload.count == null ? prevResult.count : payload.count,
            finished: payload.finished == null ? prevResult.finished : payload.finished,
            list: uniqBy(insuredsCopy, 'id'),
        };
        this.insureds = {
            ...this.insureds,
            [payload.rpName]: nextResult,
        } as DashboardState['insureds'];
    },

    updateInsuredsInList(
        payload: { rpName: string, insureds: InsuranceInsured[] }) {
        const prevResult: InsuredsListStatus = this.insureds[payload.rpName] || createEmptyListStatus();
        let newInsureds = [ ...prevResult.list ];
        payload.insureds.forEach((toUpdate) => {
            const index = newInsureds.findIndex((x) => x.id === toUpdate.id);
            newInsureds = [
                ...prevResult.list.slice(0, index),
                toUpdate,
                ...prevResult.list.slice(index + 1),
            ];
        });
        const nextResult: InsuredsListStatus = {
            loading: false,
            count: prevResult.count,
            finished: prevResult.finished,
            list: uniqBy(newInsureds, 'id'),
        };
        this.insureds = {
            ...this.insureds,
            [payload.rpName]: nextResult,
        } as DashboardState['insureds'];
    },

    patchInsuredsInList(payload: { rpName: string, insuredsPatch: InsuredPatchResult[] }) {
        payload.insuredsPatch.forEach((insuredPatch) => {
            this.insureds[payload.rpName].list = this.insureds[payload.rpName].list.map((insured) => {
                if (insured.id !== insuredPatch.id) {
                    return insured;
                }
                const patchedInsured = { ...insured };
                patchedInsured.displayName = insuredPatch.displayName?.newValue ?? patchedInsured.displayName;
                patchedInsured.contactName = insuredPatch.contactName?.newValue ?? patchedInsured.contactName;
                patchedInsured.contactEmail = insuredPatch.contactEmail?.newValue ?? patchedInsured.contactEmail;
                patchedInsured.contactPhoneNumber = insuredPatch.contactPhoneNumber?.newValue
                    ?? patchedInsured.contactPhoneNumber;
                patchedInsured.active = insuredPatch.active?.newValue ?? patchedInsured.active;
                patchedInsured.complianceStatus = insuredPatch.complianceStatus?.newValue
                    ?? patchedInsured.complianceStatus;
                patchedInsured.nextExpiration = insuredPatch.nextExpiration === undefined
                    ? patchedInsured.nextExpiration
                    : insuredPatch.nextExpiration.newValue;
                patchedInsured.paused = insuredPatch.paused?.newValue ?? patchedInsured.paused;
                if (insuredPatch.insuredFields) {
                    Object.entries(insuredPatch.insuredFields)
                        .forEach(([ k, v ]) => {
                            if (!patchedInsured.insuredFields) {
                                patchedInsured.insuredFields = {};
                            }
                            patchedInsured.insuredFields[k] = v.newValue;
                        });
                }
                return patchedInsured;
            });
        });
    },

    failInsuredsListUpdate(payload: { rpName: string }) {
        const prevResult: InsuredsListStatus = this.insureds[payload.rpName] || createEmptyListStatus();
        const nextResult: InsuredsListStatus = {
            loading: false,
            count: prevResult.count,
            finished: prevResult.finished,
            list: [ ...prevResult.list ],
        };

        this.insureds = {
            ...this.insureds,
            [payload.rpName]: nextResult,
        } as DashboardState['insureds'];
    },

    startLoadingInsuredFields(payload: { rpName: string }) {
        this.insuredFields = {
            ...this.insuredFields,
            [payload.rpName]: {
                ...(this.insuredFields[payload.rpName] as InsuredFieldsListStatus),
                status: OperationStatus.loading,
                list: [],
            },
        };
    },

    finishLoadingInsuredFields(payload: { rpName: string, insuredFields: InsuranceInsuredField[] }) {
        this.insuredFields = {
            ...this.insuredFields,
            [payload.rpName]: {
                ...(this.insuredFields[payload.rpName] as InsuredFieldsListStatus),
                status: OperationStatus.success,
                list: payload.insuredFields,
            },
        };
    },

    failLoadingInsuredFields(payload: { rpName: string }) {
        this.insuredFields = {
            ...this.insuredFields,
            [payload.rpName]: {
                ...(this.insuredFields[payload.rpName] as InsuredFieldsListStatus),
                status: OperationStatus.error,
                list: [],
            },
        };
    },

    startLoadingCoverageTypes(payload: { rpName: string }) {
        this.coverageTypes = {
            ...this.coverageTypes,
            [payload.rpName]: {
                ...(this.coverageTypes[payload.rpName] as CoverageTypeListStatus),
                status: OperationStatus.loading,
                list: [],
            },
        };
    },

    finishLoadingCoverageTypes(payload: { rpName: string, coverageTypes: string[] }) {
        this.coverageTypes = {
            ...this.coverageTypes,
            [payload.rpName]: {
                ...(this.coverageTypes[payload.rpName] as CoverageTypeListStatus),
                status: OperationStatus.success,
                list: payload.coverageTypes,
            },
        };
    },

    failLoadingCoverageTypes(payload: { rpName: string }) {
        this.coverageTypes = {
            ...this.coverageTypes,
            [payload.rpName]: {
                ...(this.coverageTypes[payload.rpName] as CoverageTypeListStatus),
                status: OperationStatus.error,
                list: [],
            },
        };
    },

    startLoadingInsuredStatistics(payload: { rpName: string }) {
        this.insuredStatistics = {
            ...this.insuredStatistics,
            [payload.rpName]: {
                ...createEmptyInsuredStatisticsStatus(),
                ...(this.insuredStatistics[payload.rpName] as InsuredStatisticsStatus),
                status: OperationStatus.loading,
            },
        };
    },

    finishLoadingInsuredStatistics(payload: { rpName: string, insuredStatistics: InsuranceInsuredStatistics }) {
        this.insuredStatistics = {
            ...this.insuredStatistics,
            [payload.rpName]: {
                ...(this.insuredStatistics[payload.rpName] as InsuredStatisticsStatus),
                status: OperationStatus.success,
                statistics: payload.insuredStatistics,
            },
        };
    },

    failLoadingInsuredStatistics(payload: { rpName: string }) {
        this.insuredStatistics = {
            ...this.insuredStatistics,
            [payload.rpName]: {
                ...createEmptyInsuredStatisticsStatus(),
                ...(this.insuredStatistics[payload.rpName] as InsuredStatisticsStatus),
                status: OperationStatus.error,
            },
        };
    },

    startLoadingCoveragesInfo(payload: { rpName: string }) {
        this.coverages = {
            ...this.coverages,
            [payload.rpName]: {
                ...(this.coverages[payload.rpName] as InsuranceCoveragesStatus),
                status: OperationStatus.loading,
                coverages: [],
            },
        };
    },

    finishLoadingCoveragesInfo(payload: { rpName: string, coverages: any }) {
        this.coverages = {
            ...this.coverages,
            [payload.rpName]: {
                ...(this.coverages[payload.rpName] as InsuranceCoveragesStatus),
                status: OperationStatus.success,
                coverages: payload.coverages,
            },
        };
    },

    failLoadingCoveragesInfo(payload: { rpName: string }) {
        this.coverages = {
            ...this.coverages,
            [payload.rpName]: {
                ...(this.coverages[payload.rpName] as InsuranceCoveragesStatus),
                status: OperationStatus.error,
                coverages: [],
            },
        };
    },

    startLoadingRequests(payload: { rpName: string, insuredId: string }) {
        this.requests = {
            ...this.requests,
            [payload.rpName]: {
                requests: {
                    ...(this.requests[payload.rpName]?.requests || {}),
                    [payload.insuredId]: {
                        status: OperationStatus.loading,
                        requests: [],
                    },
                },
            },
        };
    },

    finishLoadingRequests(payload: { rpName: string, insuredId: string, requests: InsuranceVerificationRequest[] }) {
        this.requests = {
            ...this.requests,
            [payload.rpName]: {
                requests: {
                    ...(this.requests[payload.rpName]?.requests || {}),
                    [payload.insuredId]: {
                        status: OperationStatus.success,
                        requests: payload.requests,
                    },
                },
            },
        };
    },

    failLoadingRequests(payload: { rpName: string, insuredId: string }) {
        this.requests = {
            ...this.requests,
            [payload.rpName]: {
                requests: {
                    ...(this.requests[payload.rpName]?.requests || {}),
                    [payload.insuredId]: {
                        status: OperationStatus.error,
                        requests: [],
                    },
                },
            },
        };
    },

    startLoadingRequestsConfig(payload: { rpName: string }) {
        this.requestsConfig = {
            ...this.requestsConfig,
            [payload.rpName]: {
                status: OperationStatus.loading,
                data: null,
            },
        };
    },

    finishLoadingRequestsConfig(payload: { rpName: string, requestsConfig: InsuranceRequestsConfig }) {
        this.requestsConfig = {
            ...this.requestsConfig,
            [payload.rpName]: {
                status: OperationStatus.success,
                data: payload.requestsConfig,
            },
        };
    },

    failLoadingRequestsConfig(payload: { rpName: string }) {
        this.requestsConfig = {
            ...this.requestsConfig,
            [payload.rpName]: {
                status: OperationStatus.error,
                data: null,
            },
        };
    },

    startLoadingSubmissionLink(payload: { rpName: string, requestId: string }) {
        this.submissionLinks = {
            ...this.submissionLinks,
            [payload.rpName]: {
                submissionLinks: {
                    ...(this.submissionLinks[payload.rpName]?.submissionLinks || {}),
                    [payload.requestId]: {
                        status: OperationStatus.loading,
                        submissionLink: '',
                    },
                },
            },
        };
    },

    finishLoadingSubmissionLink(payload: { rpName: string, requestId: string, submissionLink: string }) {
        this.submissionLinks = {
            ...this.submissionLinks,
            [payload.rpName]: {
                submissionLinks: {
                    ...(this.submissionLinks[payload.rpName]?.submissionLinks || {}),
                    [payload.requestId]: {
                        status: OperationStatus.success,
                        submissionLink: payload.submissionLink,
                    },
                },
            },
        };
    },

    failLoadingSubmissionLink(payload: { rpName: string, requestId: string }) {
        this.submissionLinks = {
            ...this.submissionLinks,
            [payload.rpName]: {
                submissionLinks: {
                    ...(this.submissionLinks[payload.rpName]?.submissionLinks || {}),
                    [payload.requestId]: {
                        status: OperationStatus.success,
                        submissionLink: '',
                    },
                },
            },
        };
    },

    updateCategorizedEnumLabels(payload: RawCategorizedEnumLabels) {
        this.categorizedEnumLabels = mapValues(payload,
            (labelObjects) => labelObjects.reduce((acc, labelObj) => ({
                ...acc,
                [labelObj.token]: omit(labelObj, 'token'),
            }), {} as Record<string, Omit<EnumLabelObject, 'token'>>),
        );
    },
}));

const {
    instantiateActions,
    instantiateModule,
    getActions,
} = createActionFactories(({ rpweb }: DashboardRequirements) => ({
    async loadNextInsureds(payload: {
        rpName: string;
        sort?: string | null;
        filters?: InsuredFilters;
    }) {
        const { rpName, sort, filters } = payload;
        const currentStatus = this.state.insureds[rpName] || createEmptyListStatus();

        // Ignore when it is already updating
        if (currentStatus.loading) {
            return;
        }

        // Mark as "during update"
        this.mutations.startInsuredsListUpdate({ rpName });

        let pausedQuery = null;
        // TODO(PRODUCT-17634): remove this when paused becomes part of verification status on the data level
        if (filters) {
            if (filters.paused) {
                pausedQuery = { paused: Boolean(filters.paused) };
            } else {
                // false or empty
                pausedQuery = filters.verificationStatus ? { paused: false } : null;
            }
        }

        // Build query options
        const queryOptions = {
            skip: currentStatus.list.length,
            limit: dashboardConfig.recordsPerPage,
            sort: sort || null,
            ...pausedQuery,
            ...(!(filters?.showDeactivated === 'true') && { active: true }),
            ...(omit(filters, [ 'showDeactivated', 'paused' ])),
        };

        try {
            const { insureds, count } = await rpweb.getInsuranceInsureds(rpName, queryOptions);
            const latestStatus = this.state.insureds[rpName] || createEmptyListStatus();
            // Handle race condition
            if (!latestStatus.loading) {
                return;
            }
            // Process data
            const list = insureds;
            this.mutations.pushToInsuredsList({
                rpName,
                count,
                insureds: list,
                finished: list.length < queryOptions.limit,
            });
        } catch (error) {
            this.mutations.pushToInsuredsList({ rpName, insureds: [] });
            await getPersistingErrorActions(this).showError(error);
        }
    }, async loadInsured(payload: {
        rpName: string;
        id: string;
    }) {
        const { rpName, id } = payload;
        try {
            const insured = await rpweb.getInsuranceInsured(rpName, id);
            this.mutations.pushToInsuredsList({
                rpName,
                count: 1,
                insureds: [ insured ],
            });
        } catch (error) {
            if (error.reason === 'not-found') {
                console.error(error);
                throw error;
            }
            await getPersistingErrorActions(this).showError(error);
        }
    }, async loadInsuredFields(payload: {
        rpName: string;
    }) {
        const { rpName } = payload;
        const currentStatus = this.state.insuredFields[rpName] || createEmptyInsuredFieldStatus();
        // Ignore when it is already updating
        if (currentStatus.status === OperationStatus.loading) {
            return;
        }
        this.mutations.startLoadingInsuredFields({ rpName });
        try {
            const insuredFields = await rpweb.getInsuranceInsuredFields(rpName);
            const latestStatus = this.state.insuredFields[rpName] || createEmptyInsuredFieldStatus();

            // Handle race condition
            if (latestStatus.status !== OperationStatus.loading &&
                latestStatus.status !== OperationStatus.uninitialized) {
                return;
            }
            // Process data
            this.mutations.finishLoadingInsuredFields({
                rpName,
                insuredFields,
            });
        } catch (error) {
            this.mutations.failLoadingInsuredFields({ rpName });
            await getPersistingErrorActions(this).showError(error);
        }
    }, async loadCoverageTypes(payload: {
        rpName: string;
    }) {
        const { rpName } = payload;
        const currentStatus = this.state.coverages[rpName] || createEmptyCoveragesInfoStatus();
        // Ignore when it is already updating
        if (currentStatus.status === OperationStatus.loading) {
            return;
        }
        this.mutations.startLoadingCoverageTypes({ rpName });
        try {
            const rpAttributesData = await rpweb.getRpUsedAttributesData(rpName);
            const coverageTypes = rpAttributesData.coverageTypes
                .map((coverageTypeAttributes) => coverageTypeAttributes.coverageType)
                .filter((coverageType) => coverageType !== 'BROKER_INFO');
            const latestStatus = this.state.coverageTypes[rpName] || createEmptyCoverageTypesStatus();

            // Handle race condition
            if (latestStatus.status !== OperationStatus.loading &&
                latestStatus.status !== OperationStatus.uninitialized) {
                return;
            }
            // Process data
            this.mutations.finishLoadingCoverageTypes({ rpName, coverageTypes });
        } catch (error) {
            this.mutations.failLoadingCoverageTypes({ rpName });
            await getPersistingErrorActions(this).showError(error);
        }
    }, async loadInsuredStatistics(payload: {
        rpName: string;
    }) {
        const { rpName } = payload;
        const currentStatus = this.state.insuredStatistics[rpName] || createEmptyInsuredStatisticsStatus();
        // Ignore when it is already updating
        if (currentStatus.status === OperationStatus.loading) {
            return;
        }
        this.mutations.startLoadingInsuredStatistics({ rpName });
        try {
            const insuredStatistics = await rpweb.getInsuranceInsuredStatistics(rpName);
            const latestStatus = this.state.insuredStatistics[rpName] || createEmptyInsuredStatisticsStatus();
            // Handle race condition
            if (latestStatus.status !== OperationStatus.loading &&
                latestStatus.status !== OperationStatus.uninitialized) {
                return;
            }
            // Process data
            this.mutations.finishLoadingInsuredStatistics({ rpName, insuredStatistics });
        } catch (error) {
            this.mutations.failLoadingInsuredStatistics({ rpName });
            await getPersistingErrorActions(this).showError(error);
        }
    }, async loadRequestsConfig(payload: {
        rpName: string;
    }) {
        const { rpName } = payload;
        const currentStatus = this.state.requestsConfig[rpName] || createEmptyRequestsConfigStatus();
        // Ignore when it is already updating
        if (currentStatus.status === OperationStatus.loading) {
            return;
        }
        this.mutations.startLoadingRequestsConfig({ rpName });
        try {
            const requestsConfig = await rpweb.getInsuranceRequestsConfig(rpName);
            const latestStatus = this.state.requestsConfig[rpName] || createEmptyRequestsConfigStatus();
            // Handle race condition
            if (latestStatus.status !== OperationStatus.loading &&
                latestStatus.status !== OperationStatus.uninitialized) {
                return;
            }
            // Process data
            this.mutations.finishLoadingRequestsConfig({ rpName, requestsConfig });
        } catch (error) {
            console.warn('Loading requests config error', error);
            this.mutations.failLoadingRequestsConfig({ rpName });
        }
    }, async getInsuredCoverages(payload: {
        rpName: string;
        insuredId: string;
    }) {
        const { rpName, insuredId } = payload;
        const currentStatus = this.state.insuredStatistics[rpName] || createEmptyCoveragesInfoStatus();
        // Ignore when it is already updating
        if (currentStatus.status === OperationStatus.loading) {
            return;
        }
        this.mutations.startLoadingCoveragesInfo({ rpName });
        try {
            const coverages = await rpweb.getInsuranceCoveragesInfo(rpName, insuredId);
            // Process data
            this.mutations.finishLoadingCoveragesInfo({ rpName, coverages });
        } catch (error) {
            this.mutations.failLoadingCoveragesInfo({ rpName });
            await getPersistingErrorActions(this).showError(error);
        }
    },
    updateInsuredsInList(payload: { rpName: string, insureds: InsuranceInsured[] }) {
        this.mutations.updateInsuredsInList({ rpName: payload.rpName, insureds: payload.insureds });
    },
    clearInsuredsList(payload: { rpName: string }) {
        this.mutations.clearInsuredsList(payload.rpName);
    },
    clearInsuredFieldsList(payload: { rpName: string }) {
        this.mutations.clearInsuredFieldsList(payload.rpName);
    },
    clearCoverageTypeList(payload: { rpName: string }) {
        this.mutations.clearCoverageTypes(payload.rpName);
    },
    clearInsuredStatistics(payload: { rpName: string }) {
        this.mutations.clearInsuredStatistics(payload.rpName);
    },
    clearInsuredRequests(payload: { rpName: string }) {
        this.mutations.clearRequests(payload.rpName);
    },
    clearRequestsConfig(payload: { rpName: string }) {
        this.mutations.clearRequestsConfig(payload.rpName);
    },
    clearSubmissionLinks(payload: { rpName: string }) {
        this.mutations.clearSubmissionLinks(payload.rpName);
    },
    async patchInsuredsInList(payload: { rpName: string, insuredsPatch: InsuredPatchResult[] }) {
        this.mutations.patchInsuredsInList(payload);
    },
    async loadInsuredRequests(payload: {
        rpName: string;
        insuredId: string;
    }) {
        const { rpName, insuredId } = payload;
        const currentRpStatus = this.state.requests[rpName];
        const currentStatus = currentRpStatus && currentRpStatus.requests
            ? currentRpStatus.requests[insuredId]
            : createEmptyRequestStatus();
        // Ignore when it is already updating
        if (currentStatus && currentStatus.status === OperationStatus.loading) {
            return;
        }
        this.mutations.startLoadingRequests({ rpName, insuredId });
        try {
            const requests = await rpweb.getInsuranceVerificationRequests(rpName, insuredId);
            // Process data
            this.mutations.finishLoadingRequests({ rpName, insuredId, requests });
        } catch (error) {
            this.mutations.failLoadingRequests({ rpName, insuredId });
            await getPersistingErrorActions(this).showError(error);
        }
    },
    async loadInsuredSubmissionLink(payload: {
        rpName: string;
        requestId: string;
    }) {
        const { rpName, requestId } = payload;
        const currentRpStatus = this.state.submissionLinks[rpName];
        const currentStatus = currentRpStatus && currentRpStatus.submissionLinks
            ? currentRpStatus.submissionLinks[requestId]
            : createEmptySubmissionLinkStatus();
        // Ignore when it is already updating
        if (currentStatus && currentStatus.status === OperationStatus.loading) {
            return;
        }
        this.mutations.startLoadingSubmissionLink({ rpName, requestId });
        try {
            const submissionLink = await rpweb.getInsuranceSubmissionLink(rpName, requestId);
            // Process data
            this.mutations.finishLoadingSubmissionLink({ rpName, requestId, submissionLink });
        } catch (error) {
            this.mutations.failLoadingSubmissionLink({ rpName, requestId });
            await getPersistingErrorActions(this).showError(error);
        }
    },
    async loadCategorizedEnumLabels(payload: { rpName: string }) {
        const { rpName } = payload;
        try {
            const enumHumanReadableValues = await rpweb.getCategorizedEnumLabels(rpName);
            this.mutations.updateCategorizedEnumLabels(enumHumanReadableValues);
        } catch (error) {
            await getPersistingErrorActions(this).showError(error);
        }
    },
}));

export default {
    instantiateState,
    instantiateActions,
    instantiateMutations,
    instantiateModule,
    getActions,
};

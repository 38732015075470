import orderBy from 'lodash/orderBy';
import startCase from 'lodash/startCase';
import { Many, ListIteratee } from 'lodash';
import { InsuranceInsuredField } from '@evidentid/rpweb-api-client/types';
import {
    JsonSchemaArray,
    JsonSchemaBasicObject,
    JsonSchemaType,
    RegularJsonSchema,
} from '@evidentid/json-schema/interfaces/JsonSchema';
import { isCollateralInsuredField } from '@/utils/isCollateralInsuredField';

const insuredFieldCollateralOrder = [
    'description',
    'category',
    'uniqueIdentifier',
    'limitRequired',
    'maximumDeductible',
];

export function buildInsuredJsonSchema(
    insuredFields: InsuranceInsuredField[],
    coverageTypes: string[] = [],
    insuredFieldsSortingIteratees: Many<ListIteratee<InsuranceInsuredField>> = (_, index) => index,
    insuredFieldOrders: Parameters<typeof orderBy>[2] = []
): JsonSchemaBasicObject {
    const coverageTypeKeyLabels = coverageTypes.map((x) => (
        { key: x, label: startCase(x.toLowerCase().replace(/_/g, ' ')) }),
    );
    return {
        type: JsonSchemaType.object,
        properties: {
            displayName: {
                type: JsonSchemaType.string,
                minLength: 1,
                title: 'Insured Name',
                description: 'Business name on the policy',
                // FIXME: a proper way to validate trimmed string for empty check
                pattern: '^(?!\\s+$)',
            },
            contactEmail: {
                type: JsonSchemaType.string,
                format: 'email',
                minLength: 1,
                title: 'Primary Contact Email',
                description: 'Enter entity email',
            },
            contactName: {
                type: JsonSchemaType.string,
                title: 'Primary Contact Name',
                description: 'Enter contact name',
            },
            contactPhoneNumber: {
                type: JsonSchemaType.string,
                format: 'phone',
                title: 'Primary Contact Phone Number',
            },
            insuredFields: {
                type: JsonSchemaType.object,
                properties: {
                    ...insuredFields.reduce((accu, field) => {
                        let property;

                        if (isCollateralInsuredField(field)) {
                            property = {
                                ...field.schema,
                                items: {
                                    ...(field.schema as any).items,
                                    propertiesOrder: insuredFieldCollateralOrder,
                                },
                            } as JsonSchemaArray;
                        } else {
                            property = {
                                ...field.schema,
                                // FIXME: a proper way to validate trimmed string for empty check
                                ...(field.required && (field.schema as RegularJsonSchema).type === 'string' && { pattern: '^(?!\\s+$)' }),
                            };
                        }

                        if ((field.schema as RegularJsonSchema).type === JsonSchemaType.array && field.required) {
                            (property as JsonSchemaArray).minItems = 1;
                        }

                        accu[field.key] = property;
                        return accu;
                    }, {} as JsonSchemaBasicObject['properties']),
                },
                propertiesOrder: orderBy(insuredFields, insuredFieldsSortingIteratees, insuredFieldOrders)
                    .map((x) => x.key),
                required: insuredFields.filter((x) => x.required).map((x) => x.key),
            },
            exceptions: {
                type: JsonSchemaType.object,
                properties: coverageTypeKeyLabels.reduce((accu, coverageType) => ({
                    ...accu, [coverageType.key]: {
                        type: JsonSchemaType.string,
                        format: 'date',
                        title: `Existing ${coverageType.label} Policy Expiration Date`,
                    },
                }), {} as JsonSchemaBasicObject['properties']),
                propertiesOrder: [ ...coverageTypes ],
                required: [],
            },
        },
        propertiesOrder: [
            'displayName',
            'contactEmail',
            'contactName',
            'contactPhoneNumber',
            'insuredFields',
            'exceptions',
        ],
        required: [
            'displayName',
            'contactEmail',
            'insuredFields',
            'exceptions',
        ],
    };
}
